import { AddIcon } from "@/assets/icons";
import { formatUtils, productUtils } from "@/utils";
import {
  CloseIcon,
  Layer2Box,
  ProductImage,
  Tag,
  Text,
  Tooltip,
} from "@includes";
import { Box, Fade, IconButton, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import useProductCardLogic from "../../hooks/useProductCardLogic";
import { IProductCard } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const ProductCard: React.FC<IProductCard> = ({
  product,
  productPerRow,
  unpin,
  showUnpin,
  pin,
  setProductIndex,
  pinnable,
  currency = "€",
}) => {
  const theme = useTheme();

  const {
    ref,
    boxSize,
    hasDiscountRate,
    hasStrokePrice,
    hasPrice,
    setSrc,
    setPinSrc,
    hasCategory,
    hasTags,
    hasCategoriesIds,
    pinSrc,
    src,
    productProps,
    isHover,
    setIsHover,
  } = useProductCardLogic(productPerRow, product);

  const { t }: i18translateType = useTranslation();

  return (
    <Box
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      ref={ref}
      sx={{
        position: "relative",
        width: `calc(${100 / productPerRow}% - 10px)`,
        minWidth: `calc(${100 / productPerRow}% - 10px)`,
        flex: `calc(${100 / productPerRow}% - 5px)`,
        display: "flex",
        justifyContent: "center",
        height: "90%",
        cursor: "grab",
        alignItems: "center",
      }}
    >
      <Stack direction="row" spacing={1} sx={{ height: "100%" }}>
        <Layer2Box
          sx={{
            boxSizing: "border-box",
            border: "1px solid",
            color: theme.customPalette.others.background,
            width: boxSize,
            maxWidth: 180,
            position: "relative",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            borderRadius: 2,
          }}
        >
          {!pinnable && (
            <IconButton
              onClick={unpin}
              sx={{ position: "absolute", top: -4, right: -4, zIndex: 100 }}
            >
              <CloseIcon color={theme.customPalette.grey.default} />
            </IconButton>
          )}
          <Box
            sx={{
              height: "fit-content",
              maxWidth: 180,
              maxHeight: "60%",
              position: "relative",
            }}
          >
            {hasDiscountRate && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 30,
                  right: -5,
                  top: 5,
                }}
              >
                <Tag tagVariant="success">
                  {productUtils.formatProductProperties(
                    product?.discount_rate,
                    "discount_rate",
                    currency
                  )}
                </Tag>
              </Box>
            )}
            {product?.isDeleted && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 30,
                  left: -5,
                  top: 5,
                }}
              >
                <Tooltip title={t("product_deleted_tooltip")}>
                  <Box>
                    <Tag tagVariant="error">{t("product_deleted")}</Tag>
                  </Box>
                </Tooltip>
              </Box>
            )}

            <ProductImage
              src={product?.imgLink}
              onDragStart={(e) => {
                e.preventDefault();
              }}
              inactive={product?.inactive ?? false}
              available={product?.isAvailable ?? 0}
            />
          </Box>
          <Stack
            direction="row"
            sx={{ width: "100%", position: "relative" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip title={product?.title}>
              <Box sx={{ width: "100%" }}>
                <Text variant="body1" noWrap customStyle={{ maxWidth: "85%" }}>
                  {product?.title}
                </Text>
              </Box>
            </Tooltip>
            {pinnable && (
              <Box sx={{ position: "absolute", right: 0, zIndex: 5000 }}>
                {showUnpin ? (
                  <Tooltip title={t("pinned_tooltip")}>
                    <div>
                      <Box
                        component="img"
                        onMouseOver={() => {
                          if (product.isPinned) {
                            setSrc("unpin");
                          }
                        }}
                        onMouseLeave={() => {
                          setSrc("pin");
                        }}
                        src={`/${theme.customPalette.mode}/products/${src}.svg`}
                        sx={{
                          cursor: "pointer",
                          p: "4px",
                          borderRadius: "50%",
                        }}
                        onClick={unpin}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("pin_product")}>
                    <div>
                      <Box
                        component="img"
                        onMouseOver={() => {
                          setPinSrc("pin");
                        }}
                        onMouseLeave={() => {
                          setPinSrc("canPin");
                        }}
                        src={`/${theme.customPalette.mode}/products/${pinSrc}.svg`}
                        sx={{
                          cursor: "pointer",
                          p: "4px",
                          borderRadius: "50%",
                          opacity: isHover ? 1 : 0,
                        }}
                        onClick={pin}
                      />
                    </div>
                  </Tooltip>
                )}
              </Box>
            )}
          </Stack>
          {hasCategory && (
            <Tooltip title={product?.category}>
              <Box sx={{ maxWidth: "100%", width: "fit-content" }}>
                <Text textType="secondary" noWrap>
                  {product?.category}
                </Text>
              </Box>
            </Tooltip>
          )}
          {hasTags && (
            <Tooltip title={product?.tags}>
              <Box sx={{ maxWidth: "100%", width: "fit-content" }}>
                <Text textType="secondary" noWrap>
                  {product?.tags}
                </Text>
              </Box>
            </Tooltip>
          )}
          {hasCategoriesIds && (
            <Tooltip title={product?.categories_ids}>
              <Box sx={{ maxWidth: "100%", width: "fit-content" }}>
                <Text textType="secondary" noWrap>
                  {product?.categories_ids}
                </Text>
              </Box>
            </Tooltip>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            {hasPrice && (
              <Text
                variant="body2"
                customStyle={{ color: theme.customPalette.bluePurple.dark }}
              >
                {formatUtils.formatPrice(product?.salePrice, currency)}
              </Text>
            )}
            {hasStrokePrice && (
              <Text
                variant="body2"
                customStyle={{
                  textDecoration: "line-through",
                  color: theme.customPalette.grey.light,
                }}
              >
                {formatUtils.formatPrice(product?.price, currency)}
              </Text>
            )}
          </Stack>

          {productProps?.map((property: string) => {
            const prop = productUtils.getFormattedProperty(property);
            if (!!product[prop] || typeof product[prop] === "boolean")
              return (
                <div key={product[prop]}>
                  <Tooltip title={getTraduction(t, property)}>
                    <Box sx={{ maxWidth: "100%" }}>
                      <Text
                        textType="secondary"
                        noWrap={prop !== "formattedStockPerSize"}
                      >
                        {productUtils.formatProductProperties(
                          product[prop],
                          prop,
                          currency
                        )}
                      </Text>
                    </Box>
                  </Tooltip>
                </div>
              );
          })}
        </Layer2Box>
        <Fade in={isHover}>
          <Box sx={{ height: "100%", position: "relative" }}>
            <Tooltip title={t("insert_product_here")}>
              <Box
                onClick={setProductIndex}
                sx={{
                  height: "100%",
                  width: 20,
                  cursor: "pointer",
                  background: theme.customPalette.others.background,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AddIcon color={theme.customPalette.grey.default} />
              </Box>
            </Tooltip>
          </Box>
        </Fade>
      </Stack>
    </Box>
  );
};

export default ProductCard;
