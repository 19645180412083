import Close from "@/assets/icons/Close";
import { useViewport } from "@/hooks";
import { StatusEnum } from "@/utils/helpers/status/enums";
import {
  Button,
  Dialog,
  Stack,
  StyledTextField,
  StyledToggle,
  Text,
} from "@includes";
import { IconButton, useTheme } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IExperienceSettingsDialog } from "./types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { StatusType } from "@/utils/helpers/status/types";
import { client } from "@/services/graphql/apolloConfig";
import { UPDATE_EXPERIENCE } from "./requests";

const ExperienceSettingsDialog: React.FC<IExperienceSettingsDialog> = ({
  experience,
  handleClose,
  open,
  getExperiences,
}) => {
  const { isMobile } = useViewport();
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const [experienceSettings, setExperienceSettings] =
    React.useState<Dic<any>>(experience);
  const handleChangeHiddenExperience = () => {
    setExperienceSettings({
      ...experienceSettings,
      settings: {
        ...experienceSettings.settings,
        interface: {
          hidden: !experienceSettings.settings?.interface?.hidden,
        },
      },
    });
  };
  const [status, setStatus] = React.useState<StatusType>(StatusEnum.NORMAL);
  const updateExperience = () => {
    const experienceToUpdate = {
      analyticsName: experienceSettings.analyticsName,
      deployedAt: experienceSettings.deployedAt,
      settings: experienceSettings.settings,
    };

    setStatus(StatusEnum.LOADING);
    client
      .mutate({
        mutation: UPDATE_EXPERIENCE,
        variables: {
          experience: experienceToUpdate,
          id: experienceSettings.id,
        },
        fetchPolicy: "network-only",
      })
      .then(() => {
        getExperiences();
        handleClose();
        setStatus(StatusEnum.NORMAL);
      })
      .catch(() => setStatus(StatusEnum.ERROR));
  };

  const [test, setTest] = React.useState("");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "min(100vw, 550px)",
          maxWidth: "min(100vw, 550px)",
        },
      }}
    >
      <Stack spacing={2} sx={{ padding: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Text variant="h3">{t("settings")}</Text>

          <IconButton onClick={handleClose}>
            <Close color={theme.customPalette.grey.default} />
          </IconButton>
        </Stack>
        <Stack>
          {experienceSettings && (
            <Stack sx={{ p: 2 }} spacing={2}>
              <StyledTextField
                value={test}
                onChange={(e: React.ChangeEvent<any>) =>
                  setTest(e.target.value)
                }
                label={"analyticsName"}
                name={"analyticsName"}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  label="deployedAt"
                  value={experienceSettings.deployedAt}
                  onChange={(newValue: string | null) =>
                    setExperienceSettings({
                      ...experienceSettings,
                      deployedAt: dayjs(newValue).format("YYYY-MM-DD"),
                    })
                  }
                  renderInput={(params: any) => (
                    <StyledTextField
                      {...params}
                      label="Deployed at"
                      name="deployedAt"
                    />
                  )}
                />
              </LocalizationProvider>
              <Stack direction="row" alignItems="center" spacing={1}>
                <StyledToggle
                  checked={!!experienceSettings.settings?.interface?.hidden}
                  onChange={handleChangeHiddenExperience}
                />
                <Text>{t("hidden")}</Text>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack>
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
            spacing={1}
          >
            <Button
              btnVariant="outline"
              id="cancelBtn"
              onClick={handleClose}
              customStyle={{ width: isMobile ? "100%" : "50%" }}
            >
              {t("cancel")}
            </Button>
            <Button
              customStyle={{ width: isMobile ? "100%" : "50%" }}
              loading={status === StatusEnum.LOADING}
              onClick={updateExperience}
              id="updateExperience"
            >
              {t("save")}
            </Button>
          </Stack>
        </Stack>
        {status === StatusEnum.ERROR && (
          <Text textType="error">{t("default_error")}</Text>
        )}
      </Stack>
    </Dialog>
  );
};

export default ExperienceSettingsDialog;
