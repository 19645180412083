import { CloseIcon, EditIcon } from "@/assets/icons";
import { usePersistedState } from "@/hooks";
import { RootState } from "@/services/redux/store";
import {
  CustomPopper,
  Layer1Box,
  Layer2Box,
  SideModal,
  Stack,
  Text,
} from "@includes";
import { IconButton, Skeleton, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ParameterValue from "./components/ParameterValue";
import { DEFAULT_PARAM_VALUE, SIDE_MODAL_NAME } from "./enums";
import { getValue } from "./helpers";
import { IParameterSelector } from "./types";
import { VARIABLE_TYPE_ENUM } from "@/utils/enums/variables";
import { getTraduction } from "@/utils/helpers/traductions";

// Display rule params and allow to change their value
const ParameterSelector: React.FC<IParameterSelector> = ({
  parameterName,
  parameterType,
  params,
  setParams,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const catalogId = useSelector((state: RootState) => state.catalog.catalogId);
  const { experienceId } = useParams<UrlParams>();
  const [allParams, setAllParams] = usePersistedState({
    key: `allParams-${parameterName}-${catalogId}-${
      experienceId ?? "notSaved"
    }`,
    initialValue: 100,
  });
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClosePopper = () => setOpenPopper(false);

  const onClose = () => setIsOpen(false);

  const handleOpen = () => setIsOpen(true);
  const addProduct = (product: any) => {
    setAllParams({
      ...allParams,
      [parameterType]: [...(allParams[parameterType] ?? []), product],
    });
  };

  const removeProduct = (productId: string) => {
    setAllParams({
      ...allParams,
      [parameterType]: [
        ...allParams[parameterType]?.filter(
          (product: Dic<any>) => product.id != productId
        ),
      ],
    });
  };

  const updateParams = () => {
    let res: Dic<any> = {};
    Object.keys(allParams).forEach((key: string) => {
      if (
        key === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
        key === VARIABLE_TYPE_ENUM.ITEM_ID
      ) {
        res[parameterName] = allParams[key].map(
          (product: Dic<any>) => product.id
        );
      } else {
        res[parameterName] = allParams[key];
      }
    });
    setParams({ ...res });
  };

  const initParam = () => {
    if (
      parameterType === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
      parameterType === VARIABLE_TYPE_ENUM.ITEM_ID
    ) {
      setAllParams({ ...allParams, [parameterType]: [] });
    } else if (
      parameterType === VARIABLE_TYPE_ENUM.CATEGORY_ID ||
      VARIABLE_TYPE_ENUM.ARRAY_CATEGORY_ID
    ) {
    } else
      setAllParams({ [parameterType]: DEFAULT_PARAM_VALUE[parameterType] });
  };

  useEffect(() => {
    updateParams();
  }, [allParams]);

  useEffect(() => {
    if (isEmpty(allParams)) {
      initParam();
    }
  }, [parameterType]);

  const handleChange = (parameterType: VARIABLE_TYPE_ENUM) => (value: any) => {
    setAllParams({ [parameterType]: value });
  };

  if (loading)
    return (
      <Skeleton
        width="50%"
        height={50}
        animation="wave"
        variant="rounded"
        sx={{ bgcolor: theme.customPalette.others.layer_2 }}
      />
    );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (
      parameterType === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
      parameterType === VARIABLE_TYPE_ENUM.ITEM_ID
    )
      handleOpen();
    else {
      setOpenPopper(true);
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <SideModal
        params={{
          selectedProducts: allParams[parameterType],
          addProduct,
          type: "set parameter",
          removeProduct,
        }}
        isOpen={isOpen}
        onClose={onClose}
        child={SIDE_MODAL_NAME[parameterType]}
        position="right"
      />
      <CustomPopper
        handleClose={handleClosePopper}
        open={openPopper}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        sx={{ zIndex: 9999999 }}
      >
        <Layer1Box
          sx={{
            mt: 1,
            position: "relative",
            "&:after": {
              content: '""',
              height: "14px",
              width: "14px",
              position: "absolute",
              background: theme.customPalette.others.layer_1,
              top: "-8px",
              left: "50%",
              transform: "translate(-50%, 0) rotate(45deg)",
              borderLeft: `1px solid ${theme.customPalette.others.background}`,
              borderTop: `1px solid ${theme.customPalette.others.background}`,
            },
          }}
        >
          <Stack direction="row" alignItems="start" spacing={2}>
            <Stack spacing={1} alignItems="center">
              <Text>{t("please_enter_parameter_value")}</Text>
              <ParameterValue
                value={allParams[parameterType]}
                parameterType={parameterType}
                handleChange={handleChange(parameterType)}
                parameterName={getTraduction(t, parameterName)}
              />
            </Stack>
            <IconButton onClick={handleClosePopper}>
              <CloseIcon color={theme.customPalette.grey.default} />
            </IconButton>
          </Stack>
        </Layer1Box>
      </CustomPopper>
      <Layer2Box
        sx={{
          padding: "3px 14px",
          borderRadius: 2,
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <EditIcon color={theme.customPalette.grey.default} />
          <Text noWrap variant="body2" customStyle={{ width: "fit-content" }}>
            {getTraduction(t, parameterName)}:
          </Text>
          <Text noWrap variant="body2">
            {getValue(allParams, parameterType)}
          </Text>
        </Stack>
      </Layer2Box>
    </>
  );
};

export default ParameterSelector;
