import { useEffect } from "react";
import usePersistedState from "../usePersistedState";
import useSetState from "../useSetState";
import { abTastyTheme, darkTheme } from "../../theme";
import { isEmpty } from "lodash";
import { THEME_ENUM } from "./enums";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";

const useThemeApplication = () => {
  const query = new URLSearchParams(location.search);
  const themeUrl = query.get("theme")?.toUpperCase();
  const [themeLocalStorage, setThemeLocalStorage] = usePersistedState({
    key: `accountTheme`,
    initialValue: THEME_ENUM.DEFAULT,
  });

  const themeByState = useSelector(
    (state: RootState) => state.account.accountTheme
  );

  const getTheme = (theme: string) => {
    if (theme?.toUpperCase() === THEME_ENUM.DEFAULT) return darkTheme;
    return abTastyTheme;
  };

  const [theme, setTheme] = useSetState(
    getTheme(themeByState) ?? getTheme(themeLocalStorage) ?? darkTheme
  );

  const isABTastyTheme = themeLocalStorage === THEME_ENUM.ABTASTY;
  useEffect(() => {
    if (
      themeUrl &&
      !isEmpty(themeUrl) &&
      Object.values(THEME_ENUM).includes(themeUrl as THEME_ENUM)
    ) {
      setTheme(getTheme(themeUrl));
      setThemeLocalStorage(themeUrl);
    }
  }, [themeUrl]);

  useEffect(() => {
    if (!isEmpty(themeByState) && !themeUrl) {
      setTheme(getTheme(themeByState));
      setThemeLocalStorage(themeByState);
    }
  }, [themeByState]);

  return {
    theme,
    isABTastyTheme,
  };
};

export default useThemeApplication;
