import { searchType } from "./types";

export const FIRST = 150;

export const REQUIRED_INTEGRATIONS = {
  MULTIPLY: ["CMS (Shopify, Prestashop)"],
  FLASHBACK: [],
  EMAILING: ["CMS (Shopify, Prestashop)", "Brevo"],
  CONNECT: [],
  PROPULSE: [],
  SWITCH: [],
  EMERCH: ["CMS (Shopify, Prestashop)"],
};

export const mapSearchTypeToKey: Dic<string> = {
  MULTIPLY: "recommendations/site",
  SWITCH: "targeted_popups",
  FLASHBACK: "recently_viewed_products",
  PROPULSE: "last_session_recovery",
  CONNECT: "automatic_authentication",
  EMERCH: "merchandizing",
  EMAILING: "recommendations/emailing",
};

export const mapExperienceTypeToListTitle: Dic<string> = {
  MULTIPLY: "site_web",
  EMAILING: "mail",
};

export const MAPPED_COLUMNS_TO_HIDE: Dic<Array<string>> = {
  MULTIPLY: [],
  EMERCH: ["userRate", "weightInCa", "impact"],
  FLASHBACK: [],
  PROPULSE: [],
  CONNECT: ["userRate", "weightInCa", "impact", "actions"],
  EMAILING: ["userRate", "weightInCa", "impact"],
  SWITCH: [],
};

export enum SEARCH_TYPE_ENUM {
  MULTIPLY = "MULTIPLY",
  EMERCH = "EMERCH",
  PROPULSE = "PROPULSE",
  SWITCH = "SWITCH",
  CONNECT = "CONNECT",
  FLASHBACK = "FLASHBACK",
  EMAILING = "EMAILING",
}
