import Close from "@/assets/icons/Close";
import { useViewport } from "@/hooks";
import { graphqlUtils } from "@/utils";
import { Box, Button, Dialog, Stack, Text } from "@includes";
import { IconButton, useTheme } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IDeleteExperienceDialog } from "./types";
import { DELETE_EXPERIENCE } from "./requests";

const DeleteExperienceDialog: React.FC<IDeleteExperienceDialog> = ({
  open,
  handleClose,
  experienceId,
  experienceName,
  updateExperiences,
}) => {
  const { isMobile } = useViewport();
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const [deletingExperience, setDeletingExperience] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleDelete = () => {
    if (experienceId) {
      setDeletingExperience(true);
      setError(false);
      graphqlUtils
        .mutate(DELETE_EXPERIENCE, {
          experienceId: parseInt(experienceId, 10),
        })
        .then((res: any) => {
          setDeletingExperience(false);
          updateExperiences(experienceId, "remove");
          handleClose();
        })
        .catch((e: any) => {
          setDeletingExperience(false);
          setError(true);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "min(100vw, 550px)",
            maxWidth: "min(100vw, 550px)",
          },
        }}
      >
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Stack spacing={1}>
              <Box
                sx={{ width: 25, height: 25, pt: 1 }}
                component="img"
                alt="lock"
                src={`/${theme.customPalette.mode}/status/warning.svg`}
              />
              <Text variant="h3">
                {t("delete_experience_title")} {experienceName}
              </Text>
            </Stack>
            <IconButton onClick={handleClose}>
              <Close color={theme.customPalette.grey.default} />
            </IconButton>
          </Stack>
          <DialogContent sx={{ padding: 0 }}>
            <DialogContentText sx={{ color: theme.customPalette.grey.default }}>
              {t("can_restore_data")}
              <br /> {t("are_you_sure")}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ gap: 3 }}>
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
              spacing={1}
            >
              <Button
                btnVariant="outline"
                id="cancelBtn"
                onClick={handleClose}
                customStyle={{ width: isMobile ? "100%" : "50%" }}
              >
                {t("cancel")}
              </Button>
              <Button
                customStyle={{ width: isMobile ? "100%" : "50%" }}
                loading={deletingExperience}
                onClick={handleDelete}
                id="deleteCollectionBtn"
                btnVariant="error"
              >
                {t("confirm")}
              </Button>
            </Stack>
          </DialogActions>
          {error && <Text textType="error">{t("default_error")}</Text>}
        </Stack>
      </Dialog>
    </div>
  );
};

export default DeleteExperienceDialog;
