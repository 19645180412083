import ShortUniqueId from "short-unique-id";
import { objectUtils, formatUtils } from "@/utils";
import { isEmpty } from "lodash";

/**
 * Utility class for handling product-related operations.
 */
export class ProductUtils {
  /**
   * Formats product properties based on the provided value and property name.
   *
   * @param value - The value to be formatted.
   * @param property - The name of the property.
   * @params currency - The currency symbol (default "€")
   * @returns The formatted property value.
   */
  formatProductProperties = (
    value: any,
    property: string,
    currency: string = "€"
  ) => {
    if (["potential_turnover"].includes(property))
      return formatUtils.formatCurrency(value, 2, currency);
    if (property.includes("price")) return formatUtils.formatPrice(value);
    if (property?.includes("rate"))
      return formatUtils.formatToPercent(value / 100);
    return value?.toString();
  };

  /**
   * Gets the formatted property name.
   *
   * @param prop - The property name.
   * @returns The formatted property name.
   */
  getFormattedProperty = (prop: string) => {
    if (prop === "Stock per size") return "formattedStockPerSize";
    return prop;
  };

  /**
   * Generates a UUID (Universally Unique Identifier).
   *
   * @returns A UUID string.
   */
  getUuid = () => {
    const uid = new ShortUniqueId();
    return uid();
  };

  /**
   * Checks if the provided value is an object.
   *
   * @param value - The value to check.
   * @returns `true` if the value is an object, `false` otherwise.
   */
  isObject = (value: any) => {
    if (typeof value === "object") return true;
    return false;
  };

  /**
   * Retrieves stock information per size from the product options.
   *
   * @param product - The product data.
   * @returns A dictionary containing stock per size.
   */
  getStockPerSize = (product: Dic<any>) => {
    const res: Dic<string> = {};
    if (!product?.options || product?.options.length === 0) {
      return { "-": "-" };
    }
    try {
      let hasSizeOrStock = false;
      product?.options.forEach((o: Dic<any>) => {
        const size = o.taille || o.size;
        if (!!size && size !== "null" && o.stock != null && o.stock !== "") {
          res[size] = o.stock;
          hasSizeOrStock = true;
        }
        if (!hasSizeOrStock) {
          res["-"] = "-";
        }
      });
      return res;
    } catch (e: any) {
      return {};
    }
  };

  /**
   * Gets the primary product image link.
   *
   * @param product - The product data.
   * @returns The product image link.
   */
  getProductImg = (product: Dic<any>) => {
    if (product?.imgLink && this.isObject(product?.imgLink))
      return objectUtils.getFirstObjectValue(product?.imgLink);
    if (product?.img_link && this.isObject(product?.img_link))
      return objectUtils.getFirstObjectValue(product?.img_link);
    if (product?.img_links && this.isObject(product?.img_links?.[0]))
      return objectUtils.getFirstObjectValue(product?.img_links?.[0]);
    if (product?.img_link_2 && this.isObject(product?.img_link_2))
      return objectUtils.getFirstObjectValue(product?.img_link_2);
    if (product?.imgLink && typeof product?.imgLink === "string")
      return product?.imgLink;
    if (product?.img_link && typeof product?.img_link === "string")
      return product?.img_link;
    if (product?.img_links) return product?.img_links[0];

    if (product?.img_link_2) return product?.img_link_2;

    return "no-image";
  };

  /**
   * Retrieves the value of a specific property from the product data.
   *
   * @param product - The product data.
   * @param property - The name of the property.
   * @returns The value of the specified property.
   */
  getPropValue = (product: Dic<any>, property: string) => {
    return this.isObject(product?.[property])
      ? objectUtils.getFirstObjectValue(product?.[property])
      : product?.[property];
  };

  /**
   * Checks if the product is active.
   *
   * @param product - The product data.
   * @returns `true` if the product is active, `false` otherwise.
   */
  getIsActive = (product: Dic<any>) => {
    if (typeof product?.active !== "boolean") return true;
    return !!product?.active;
  };

  /**
   * Selects and returns relevant product information.
   *
   * @param p - The product data.
   * @param langue - The language (default is "fr").
   * @returns An object containing selected product information.
   */
  selectProductInfos = (p: any, prod?: any, langue = "fr") => {
    const data = p?.data ?? prod?.data ?? {};
    const product = { ...p, ...data };
    let last7DaysTransactionsTendencyValues = [0, 0];
    let last7DaysViewsTendencyValues = [0, 0];
    let last7DaysRevenueTendencyValues = [0, 0];
    let last7DaysConversionTendencyValues = [0, 0];
    let popularityScoreTendencyValues = [0, 0];

    try {
      const viewsLast14Days =
        product?.enrichedData?.["14_days_views"] ??
        product?.["14_days_views"] ??
        null;
      const viewsLast7Days =
        product?.enrichedData?.["7_days_views"] ??
        product?.["7_days_views"] ??
        null;

      last7DaysViewsTendencyValues = [
        viewsLast14Days - viewsLast7Days,
        viewsLast7Days,
      ];

      const transactionsLast14Days =
        product?.enrichedData?.["14_days_transactions"] ??
        product?.["14_days_transactions"] ??
        null;
      const transactionsLast7Days =
        product?.enrichedData?.["7_days_transactions"] ??
        product?.["7_days_transactions"] ??
        null;

      last7DaysTransactionsTendencyValues = [
        transactionsLast14Days - transactionsLast7Days,
        transactionsLast7Days,
      ];

      popularityScoreTendencyValues = [
        (transactionsLast14Days - transactionsLast7Days) /
          (viewsLast14Days - viewsLast7Days),
        transactionsLast7Days / viewsLast7Days,
      ];

      const revenueLast14Days =
        product?.enrichedData?.["14_days_revenue"] ??
        product?.["14_days_revenue"] ??
        null;
      const revenueLast7Days =
        product?.enrichedData?.["7_days_revenue"] ??
        product?.["7_days_revenue"] ??
        null;

      last7DaysRevenueTendencyValues = [
        revenueLast14Days - revenueLast7Days,
        revenueLast7Days,
      ];

      const conversionLast14Days =
        product?.enrichedData?.["14_days_conversion"] ??
        product?.["14_days_conversion"] ??
        null;
      const conversionLast7Days =
        product?.enrichedData?.["7_days_conversion"] ??
        product?.["7_days_conversion"] ??
        null;

      last7DaysConversionTendencyValues = [
        conversionLast14Days - conversionLast7Days,
        conversionLast7Days,
      ];
    } catch (e: any) {}

    return {
      ...p,
      ...data,
      data,
      last7DaysRevenueTendencyValues,
      last7DaysViewsTendencyValues,
      last7DaysTransactionsTendencyValues,
      last7DaysConversionTendencyValues,
      popularityScoreTendencyValues,
      stockPerSize: this.getStockPerSize(p),
      get formattedStockPerSize() {
        if (this.stockPerSize) {
          return Object.keys(this.stockPerSize)
            .map((key: string) => {
              return this.stockPerSize[key] === "-"
                ? "-"
                : `${key} (${this.stockPerSize[key]})`;
            })
            .join(", ");
        }
        return "";
      },
      discountedPrice:
        typeof product?.stroke_price === "number"
          ? product?.price
          : product?.stroke_price,
      priceBeforeDiscount: product?.stroke_price,
      potentialTurnover: formatUtils.formatPrice(product?.potential_turnover),
      isActive: this.getIsActive(product),
      get inactive() {
        return !this.isActive;
      },
      isDeleted: !!product?.deletedAt,
      isPinned: !!product?.isPinned,
      available: product?.available ? "In stock" : "Out of stock",
      availability: product?.available ? "In stock" : "Out of stock",
      isAvailable: product?.available,
      category: this.getPropValue(product, "category"),
      id: p?.id ?? this.getUuid(),
      price:
        typeof product?.stroke_price === "number"
          ? product?.stroke_price
          : product?.price,
      salePrice: product?.price,
      stroke_price:
        typeof product?.stroke_price === "number" ? product?.price : null,

      imgLink: this.getProductImg(p),
      absoluteLink: this.getPropValue(product, "absolute_link"),
      link: this.getPropValue(product, "link"),
      title: this.getPropValue(product, "title"),
      get name() {
        return this.title;
      },
      lastMonthViews:
        product?.enrichedData?.["30_days_views"] ??
        product?.lastMonthViews ??
        product?.["30_days_views"] ??
        "",

      popularityRank:
        product?.enrichedData?.popularity_rank ??
        product?.popularity_rank ??
        "",
      visibilityRank:
        product?.enrichedData?.visibility_rank ??
        product?.visibility_rank ??
        "",
      enrichedData: product?.enrichedData ?? prod?.enrichedData ?? { ...p },
      month: {
        view:
          product?.enrichedData?.["30_days_views"] ??
          product?.["30_days_views"] ??
          product?.lastMonthViews ??
          0,
        revenue:
          product?.enrichedData?.["30_days_revenue"] ??
          product?.["30_days_revenue"] ??
          product?.lastMonthRevenue ??
          0,
        transactions:
          product?.enrichedData?.["30_days_transactions"] ??
          product?.["30_days_transactions"] ??
          product?.lastMonthTransactions ??
          0,
        conversion:
          product?.enrichedData?.["30_days_conversion"] ??
          product?.["30_days_conversion"] ??
          0,
      },
      week: {
        view:
          product?.enrichedData?.["7_days_views"] ??
          product?.lastWeekViews ??
          product?.["7_days_views"] ??
          0,
        revenue:
          product?.enrichedData?.["7_days_revenue"] ??
          product?.lastWeekRevenue ??
          product?.["7_days_revenue"] ??
          0,
        transactions:
          product?.enrichedData?.["7_days_transactions"] ??
          product?.lastWeekTransactions ??
          product?.["7_days_transactions"] ??
          0,
        conversion:
          product?.enrichedData?.["7_days_conversion"] ??
          product?.["7_days_conversion"] ??
          0,
      },
      "14_days": {
        view:
          product?.enrichedData?.["14_days_views"] ??
          product?.["14_days_views"] ??
          0,
        revenue:
          product?.enrichedData?.["14_days_revenue"] ??
          product?.["14_days_revenue"] ??
          0,
        transactions:
          product?.enrichedData?.["14_days_transactions"] ??
          product?.["14_days_transactions"] ??
          0,
        conversion:
          product?.enrichedData?.["14_days_conversion"] ??
          product?.["14_days_conversion"] ??
          0,
      },
      "1_day": {
        view:
          product?.enrichedData?.["1_days_views"] ??
          product?.["1_days_views"] ??
          0,
        revenue:
          product?.enrichedData?.["1_days_revenue"] ??
          product?.["1_days_revenue"] ??
          0,
        transactions:
          product?.enrichedData?.["1_days_transactions"] ??
          product?.["1_days_transactions"] ??
          0,
        conversion:
          product?.enrichedData?.["1_days_conversion"] ??
          product?.["1_days_conversion"] ??
          0,
      },
      visibilityScore:
        product?.visibility_score ?? product?.enrichedData?.visibility_score,
      popularityScore:
        product?.popularity_score ?? product?.enrichedData?.popularity_score,
    };
  };
}
