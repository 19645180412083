import { useSelector as useXstateSelector } from "@xstate/react";
import { useMemo } from "react";
import Run from "../RunMerchandizing";
import { useConfigureMerchandizing } from "../../hooks/useConfigureMerchandizing";
import { comparisonUtils } from "@/utils";
import { IResultExample } from "./types";

const collectionSelector = (state: any) => state.context.collection;
const includedCategoriesSelector = (state: any) =>
  state.context.includedCategories;
const excludedCategoriesSelector = (state: any) =>
  state.context.excludedCategories;

const ResultExample: React.FC<IResultExample> = ({
  allCategories,
  gettingCategories,
}) => {
  const experienceServices = useConfigureMerchandizing();

  const collection = useXstateSelector(
    experienceServices.experienceService,
    collectionSelector
  );

  const includedCategories = useXstateSelector(
    experienceServices.experienceService,
    includedCategoriesSelector
  );

  const excludedCategories = useXstateSelector(
    experienceServices.experienceService,
    excludedCategoriesSelector
  );

  const formattedSettings = useMemo(() => {
    if (collection?.settings)
      return {
        collectionSettings: { ...collection?.settings, id: collection?.id },

        pushedProducts: {},
      };
    return {};
  }, [collection]);

  return (
    <Run
      settings={formattedSettings}
      includedCategories={(includedCategories ?? [])?.filter(
        comparisonUtils.areDifferent("all")
      )}
      excludedCategories={excludedCategories ?? []}
      allCategories={allCategories}
      gettingCategories={gettingCategories}
    />
  );
};

export default ResultExample;
