import axios from "axios";
import { onError } from "apollo-link-error";
import { fromPromise } from "apollo-link";
import { concat, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { potionsLocalStorage } from "@localStorage";

let isRefreshing = false;
let pendingRequests = [];

const getNewToken = () => {
  if (!potionsLocalStorage.refreshToken) {
    potionsLocalStorage.clear();
    window.location.href = `${window.location.origin}/login`;
  }
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/auth/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: `grant_type=refresh_token&refresh_token=${potionsLocalStorage.refreshToken}`,
  }).then((res) => {
    if (res?.data?.access_token) {
      potionsLocalStorage.set("token", res?.data.access_token);
      potionsLocalStorage.set("refreshToken", res?.data.refresh_token);
      return res?.data.access_token;
    } else {
      potionsLocalStorage.clear();
      window.location.href = `${window.location.origin}/login`;
    }
  });
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError?.statusCode === 401) {
      let forward$;

      if (!isRefreshing) {
        isRefreshing = true;
        forward$ = fromPromise(
          getNewToken()
            .then(({ accessToken, refreshToken }) => {
              resolvePendingRequests();
              return accessToken;
            })
            .catch((error) => {
              pendingRequests = [];
              potionsLocalStorage.clear();
              window.location.href = `${window.location.origin}/login`;
              return;
            })
            .finally(() => {
              isRefreshing = false;
            })
        ).filter((value) => Boolean(value));
      }
      forward$ = fromPromise(
        new Promise((resolve) => {
          pendingRequests.push(() => resolve());
        })
      );

      return forward$.flatMap(() => forward(operation));
    } else if (networkError) {
      // console.log(`[Network error]: ${networkError}`);
      // todo retry
    }
  }
);

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token;
  try {
    token = potionsLocalStorage.token;
  } catch (e) {
    if (!token) token = undefined;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloLink = concat(errorLink, concat(authLink, httpLink));
