import { DataGrid } from "@/includes";
import { Box, Collapse, Stack, useTheme } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import DeleteExperienceDialog from "./components/DeleteExperienceDialog";
import NoRowsOverlay from "./components/NoRowsOverlay";
import { REQUIRED_INTEGRATIONS } from "./enums";
import { ExperienceListProps } from "./types";
import useExperienceList from "./useExperienceList";
import useRole from "@/hooks/useRole";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { useHasProperty } from "@/hooks";
import ExperienceSettingsDialog from "../ExperienceSettingsDialog";

const ExperienceList = ({ searchType }: ExperienceListProps) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const requiredIntegrations = REQUIRED_INTEGRATIONS[searchType];
  const [hasRowClick, setHasRowClick] = React.useState<boolean>(false);
  const [selectedExperience, setSelectedExperience] = React.useState<Dic<any>>(
    {}
  );
  const [openSettingsDialog, setOpenSettingsDialog] =
    React.useState<boolean>(false);
  const handleOpenSettingsDialog = (experience: Dic<any>) => {
    setSelectedExperience(experience);
    setOpenSettingsDialog(true);
  };
  const handleCloseSettingsDialog = () => {
    setOpenSettingsDialog(false);
    setSelectedExperience({});
  };
  const { role, isSuperUser } = useRole();
  const { hasAnalytics } = useHasProperty();
  React.useEffect(() => {
    if (isSuperUser || role !== ROLE_ENUM.READER) {
      setHasRowClick(true);
    } else {
      setHasRowClick(false);
    }
  }, [role, isSuperUser]);

  const {
    handleClose,
    experienceToDelete,
    getExperiences,
    show,
    newRows,
    filteredColumns,
    handleEdit,
    canCreate,
    gettingExperiences,
    handleReport,
  } = useExperienceList(searchType, hasAnalytics, handleOpenSettingsDialog);

  return (
    <>
      <DeleteExperienceDialog
        handleClose={handleClose}
        open={!!experienceToDelete.id}
        experienceId={experienceToDelete.id}
        experienceName={experienceToDelete.name}
        updateExperiences={getExperiences}
      />
      <ExperienceSettingsDialog
        experience={selectedExperience}
        handleClose={handleCloseSettingsDialog}
        open={openSettingsDialog}
        getExperiences={getExperiences}
      />
      <Box
        sx={{
          padding: 0,
          borderRadius: 3,
          maxWidth: "99%",
          "& .MuiDataGrid-row.has-row-click:hover": {
            background: `${theme.customPalette.others.background} !important`,
            cursor: "pointer",
          },
        }}
      >
        <Stack spacing={1}>
          <Collapse in={show}>
            <DataGrid
              localeText={{
                footerTotalRows: t("total_rows"),
              }}
              onRowClick={(params: GridRowParams) => {
                if (hasAnalytics && params.row.canReport) {
                  handleReport(params.row.id);
                } else if (params.row.canEdit && hasRowClick) {
                  handleEdit(params.row.id);
                }
              }}
              getRowClassName={(params) =>
                (hasRowClick || params.row.canReport) && `has-row-click`
              }
              currentPage={0}
              pageSize={newRows.length}
              autoHeight
              rows={newRows}
              columns={filteredColumns}
              loading={gettingExperiences}
              componentsProps={{
                noRowsOverlay: {
                  canCreate,
                  handleEdit,
                  getExperiences,
                  requiredIntegrations,
                },
              }}
              components={{
                NoRowsOverlay,
              }}
              paginationType="server"
              pagination={false}
            />
          </Collapse>
        </Stack>
      </Box>
    </>
  );
};

export default ExperienceList;
