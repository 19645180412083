import { IIcon } from "./types";

const MoreVerticalOutlinedIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63327 5.20013C7.60234 5.20013 6.7666 4.3644 6.7666 3.33346C6.7666 2.30253 7.60234 1.4668 8.63327 1.4668C9.6642 1.4668 10.4999 2.30253 10.4999 3.33346C10.4999 4.3644 9.6642 5.20013 8.63327 5.20013ZM7.9666 3.33346C7.9666 3.70165 8.26508 4.00013 8.63327 4.00013C9.00146 4.00013 9.29994 3.70165 9.29994 3.33346C9.29994 2.96527 9.00146 2.6668 8.63327 2.6668C8.26508 2.6668 7.9666 2.96527 7.9666 3.33346ZM8.63327 9.86712C7.60234 9.86712 6.7666 9.03139 6.7666 8.00046C6.7666 6.96952 7.60234 6.13379 8.63327 6.13379C9.6642 6.13379 10.4999 6.96952 10.4999 8.00046C10.4999 9.03139 9.6642 9.86712 8.63327 9.86712ZM7.9666 8.00046C7.9666 8.36865 8.26508 8.66712 8.63327 8.66712C9.00146 8.66712 9.29994 8.36865 9.29994 8.00046C9.29994 7.63227 9.00146 7.33379 8.63327 7.33379C8.26508 7.33379 7.9666 7.63227 7.9666 8.00046ZM6.7666 12.667C6.7666 13.6979 7.60234 14.5336 8.63327 14.5336C9.6642 14.5336 10.4999 13.6979 10.4999 12.667C10.4999 11.636 9.6642 10.8003 8.63327 10.8003C7.60234 10.8003 6.7666 11.636 6.7666 12.667ZM8.63327 13.3336C8.26508 13.3336 7.9666 13.0351 7.9666 12.667C7.9666 12.2988 8.26508 12.0003 8.63327 12.0003C9.00146 12.0003 9.29994 12.2988 9.29994 12.667C9.29994 13.0351 9.00146 13.3336 8.63327 13.3336Z"
        fill={color}
      />
    </svg>
  );
};

MoreVerticalOutlinedIcon.defaultProps = {
  color: "transparent",
};

export default MoreVerticalOutlinedIcon;
