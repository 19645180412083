import { gql } from "@apollo/client/core";
import { client } from "../../index";
import useSetState from "../useSetState";
import { comparisonUtils, productUtils } from "@utils";
import useSentry from "../useSentry";
import { isEmpty } from "lodash";
import { RUN_COLLECTION, RUN_PAGE } from "./requests";
import { HasEmptyParams } from "./helpers";
import { formatValue } from "./formatters";

/**
 * Custom React hook for running collections and handling results.
 *
 * @param {string} siteId - The site identifier.
 * @returns {{ handleRun: Function, runningCollection: boolean, collectionResult: Array<Dic<any>>, handleRunPage: Function, handleRunEmail: Function, newHandleRun: Function }} - An object with functions to run collections and their results.
 */
const useRunCollection = (siteId: string) => {
  // Sentry error tracking hooks
  const { captureError, captureMessage } = useSentry();

  const [run, setRun] = useSetState({
    runningCollection: true,
    collectionResult: [],
  });

  /**
   * Function to format settings before running a collection.
   *
   * @param {any} _settings - Collection settings.
   * @returns {Dic<any>} - Formatted collection settings.
   */
  const formatSettings = (_settings: any) => {
    let formattedSettings: Dic<any> = {};
    let settings = _settings;
    let subKeys = settings?.sub_keys;

    try {
      delete settings.settings.sub_keys;
    } catch (e: any) {}
    if (!settings) return formattedSettings;
    if (settings.composed_collections) {
      formattedSettings = {
        settings: { ...settings?.settings },
        composed_collections: settings.composed_collections.map((c: any) =>
          c?.collection ? c : { collection: c, size: -1 }
        ),
      };
    } else if (settings.algorithm) {
      formattedSettings = {
        algorithm: settings.algorithm,
        settings: { ...settings.settings },
      };
    } else {
      formattedSettings = {
        ...settings,
        settings: { ...settings.settings, fields: "all" },
      };
    }
    if (subKeys) {
      formattedSettings = { ...formattedSettings, sub_keys: subKeys };
    }
    const filters = formattedSettings.settings?.filters ?? [];
    // remove date filter to run
    const newFilters = filters.filter(
      (f: Dic<any>) => !JSON.stringify(f).includes("strptime")
    );
    if (!formattedSettings.blocks)
      formattedSettings.settings.filters = newFilters;

    return {
      ...formattedSettings,
      required_parameter: settings?.required_parameter,
    };
  };

  /**
   * Function to run page api.
   *
   * @param {string} category_id - Category ID.
   * @param {any} settings - Collection settings.
   */
  const handleRunPage = (category_id: string, settings: any) => {
    try {
      const pushedProducts = settings?.pushedProducts;
      const pushedProductsIds: Array<string> = pushedProducts
        ? Object.values(pushedProducts)
        : [];
      setRun({ runningCollection: true });
      client
        .query({
          query: RUN_PAGE,
          variables: {
            id: parseInt(siteId),
            query: {
              query: {
                parameters: {
                  category_id,
                  viewed_product_ids: [],
                  cart_product_ids: [],
                  lang: "",
                  cart_price: 50,
                },
                query: {
                  experience_settings: { fields: ["all"] },
                  page: {
                    collection: {
                      query: {
                        ...settings.collectionSettings,
                        category_id,
                      },
                    },
                    settings: {
                      pushed_products: pushedProducts,
                    },
                  },
                },
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((res: any) => {
          setRun({ runningCollection: false });
          if (res?.data) {
            setRun({
              collectionResult:
                res?.data?.queryPageApi?.response?.products?.map(
                  (p: Dic<any>) => {
                    const isPinned = !!pushedProductsIds?.find(
                      (pId: string) => pId === p?.id
                    );
                    return productUtils.selectProductInfos({ ...p, isPinned });
                  }
                ),
            });
          }
        })
        .catch((error: any) => {
          setRun({ runningCollection: false, collectionResult: [] });
          captureError(error.message);
        });
    } catch (error: any) {
      setRun({ runningCollection: false, collectionResult: [] });
      captureError(error.message);
    }
  };

  /**
   * Function to handle running an email collection.
   *
   * @param {Dic<any>} settings - Collection settings.
   * @param {Dic<string>} value - Values for the email.
   */
  const handleRunEmail = (settings: Dic<any>, value: Dic<string>) => {
    setRun({ runningCollection: true });
    const formattedValues = formatValue(value);
    client
      .query({
        query: RUN_COLLECTION,
        variables: {
          id: parseInt(siteId),
          query: {
            query: {
              query: {
                ...settings,
                settings: {
                  ...settings.settings,
                  fields: ["all"],
                },
              },
              parameters: {
                viewed_product_ids: [],
                cart_product_ids: [],
                lang: "",
                cart_price: 50,
                ...formattedValues,
              },
            },
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res: any) => {
        setRun({ runningCollection: false });
        if (res?.data) {
          setRun({
            collectionResult:
              res?.data?.queryResourcesApi?.response?.products?.map(
                (p: Dic<any>) => productUtils.selectProductInfos(p)
              ),
          });
          captureMessage(
            `No recos for email experience with variables : ${JSON.stringify({
              id: parseInt(siteId),
              query: {
                query: {
                  query: {
                    ...settings,
                    settings: {
                      ...settings.settings,
                      fields: ["all"],
                    },
                  },
                  parameters: {
                    viewed_product_ids: [],
                    cart_product_ids: [],
                    lang: "",
                    cart_price: 50,
                    ...formattedValues,
                  },
                },
              },
            })}`
          );
        }
      })
      .catch((error: any) => {
        setRun({ runningCollection: false, collectionResult: [] });
        captureError(error.message);
      });
  };

  /**
   * Function to handle running a collection.
   *
   * @param {Array<number>} productIds - Product IDs.
   * @param {Dic<any>} collectionSettings - Collection settings.
   * @param {string} categoryId - Category ID.
   * @param {number} cartTotal - Cart total.
   * @param {Dic<any>} params - Additional parameters.
   */
  const handleRun = (
    productIds: Array<number>,
    collectionSettings: Dic<any>,
    categoryId = "",
    cartTotal = 0,
    params = {}
  ) => {
    setRun({ runningCollection: true });
    client
      .query({
        query: RUN_COLLECTION,
        variables: {
          id: parseInt(siteId),
          query: {
            query: {
              query: {
                ...formatSettings(collectionSettings),
              },
              parameters: {
                product_ids: productIds,
                category_id: categoryId,
                product_id: productIds?.[0],
                viewed_product_ids: productIds,
                cart_product_ids: [],
                lang: "",
                cart_price: 50,
                ...params,
              },
            },
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res: any) => {
        setRun({ runningCollection: false });

        if (res?.data) {
          setRun({
            collectionResult:
              res?.data?.queryResourcesApi?.response?.products?.map(
                (p: Dic<any>) => productUtils.selectProductInfos(p)
              ),
          });
          if (res?.data?.queryResourcesApi?.response?.products?.length === 0) {
            captureMessage(
              `No recos for collection with variables : ${JSON.stringify({
                id: parseInt(siteId),
                query: {
                  query: {
                    query: {
                      ...formatSettings(collectionSettings),
                    },
                    parameters: {
                      product_ids: productIds,
                      category_id: categoryId,
                      product_id: productIds?.[0],
                      viewed_product_ids: productIds,
                      cart_product_ids: [],
                      lang: "",
                      cart_price: 50,
                      ...params,
                    },
                  },
                },
              })}`
            );
          }
        }
      })
      .catch((error: Error) => {
        setRun({ runningCollection: false, collectionResult: [] });
        captureError(error.message);
      });
  };

  /**
   * Function to handle running a collection with conditional rules.
   *
   * @param {Dic<any>} settings - Collection settings.
   * @param {Dic<string>} requiredParameters - Required parameters.
   * @param {Dic<Dic<any>>} params - Additional parameters.
   */
  const newHandleRun = (
    settings: Dic<any>,
    requiredParameters: Dic<string>,
    params: Dic<Dic<any>>
  ) => {
    if (!!settings?.blocks && !isEmpty(settings?.blocks)) {
      setRun({ runningCollection: true });
      client
        .query({
          query: RUN_COLLECTION,
          variables: {
            id: parseInt(siteId),
            query: {
              query: {
                query: {
                  ...formatSettings(settings),
                },
                parameters: {
                  ...params,
                },
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((res: any) => {
          setRun({ runningCollection: false });

          if (res?.data) {
            setRun({
              collectionResult:
                res?.data?.queryResourcesApi?.response?.products?.map(
                  (p: Dic<any>) => productUtils.selectProductInfos(p)
                ),
            });
          }
        })
        .catch((error: Error) => {
          setRun({ runningCollection: false, collectionResult: [] });
        });
    }
  };
  return { handleRun, ...run, handleRunPage, handleRunEmail, newHandleRun };
};

export default useRunCollection;
