import {
  useGraphQlLazyQuery,
  useRunCollection,
  useThrottle,
  useViewport,
} from "@/hooks";
import {
  Box,
  Error,
  Layer1Box,
  ParameterSelector,
  Stack,
  Text,
} from "@/includes";
import { RootState } from "@/services/redux/store";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Api from "../Api";
import DisplayPreview from "../DisplayPreview";
import { GET_CATALOG } from "./requests";
import { IRun } from "./types";
import { useTheme } from "@mui/material";

const Run: React.FC<IRun> = ({ settings, slot, params, setParams, popup }) => {
  const { t }: i18translateType = useTranslation();
  const { isMobile } = useViewport();

  const siteId = useSelector((state: RootState) => state.site.siteId);

  const requiredParameters = settings.required_input_variables ?? {};

  const { newHandleRun, runningCollection, collectionResult } =
    useRunCollection(siteId);

  const { getDatas, loading, data, error } = useGraphQlLazyQuery(
    GET_CATALOG,
    { variables: { id: siteId, first: 5, cursor: "" } },
    "cache-first"
  );

  useEffect(() => {
    if (siteId) getDatas({ variables: { id: siteId, first: 5, cursor: "" } });
  }, [siteId]);

  const throtteledParams = useThrottle(params);
  useEffect(() => {
    newHandleRun(settings, requiredParameters, throtteledParams);
  }, [settings, throtteledParams]);
  const theme = useTheme();
  if (error) return <Error />;

  return (
    <Stack spacing={1}>
      <Layer1Box>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={1}
          alignItems={isMobile ? "start" : "center"}
          sx={{ maxWidth: "100%", overflowX: "auto" }}
        >
          <Text>{t("parameters")}:</Text>

          <Stack direction={isMobile ? "column" : "row"} spacing={1}>
            {Object.keys(requiredParameters).map((key: string) => (
              <Box key={key}>
                <ParameterSelector
                  parameterName={key}
                  parameterType={requiredParameters[key]}
                  params={params}
                  setParams={setParams}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </Layer1Box>
      {popup === "api" && <Api params={throtteledParams} />}
      {popup === "preview" && (
        <DisplayPreview
          runningCollection={runningCollection}
          collectionResult={collectionResult}
          loading={loading}
          slot={slot}
        />
      )}
    </Stack>
  );
};

Run.defaultProps = {
  slot: null,
};

export default Run;
