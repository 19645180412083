import {
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  ReportIcon,
  SettingsIcon,
} from "@/assets/icons";
import MoreVerticalOutlinedIcon from "@/assets/icons/MoreVerticalOutlined";
import VisibilityIcon from "@/assets/icons/Visibility";
import HasEditingRoleWrapper from "@/components/_Templates/HasEditingRoleWrapper";
import { HoverCopyText, Tag, Text } from "@/includes";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import * as React from "react";

export const getColumns = (
  t: (text: string) => string,
  theme: Dic<any>,
  anchorEl: HTMLElement | null,
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  idOfMenuOpen: string,
  setIdOfMenuOpen: React.Dispatch<React.SetStateAction<string>>,
  hasAnalytics: boolean,
  devMode: boolean,
  setSelectedExperience: (experience: Dic<any>) => void
): GridColDef[] => [
  {
    align: "left",
    field: "name",
    headerName: t("name"),
    flex: 1,
    minWidth: 120,
  },
  {
    align: "left",
    field: "status",
    headerName: t("status"),
    sortable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Stack>
          {params.row.isActive && <Tag tagVariant="success">{t("active")}</Tag>}
          {params.row.isPreview && (
            <Tag tagVariant="info">{t("in_preview")}</Tag>
          )}
          {!params.row.isActive &&
            !!params.row.name &&
            !params.row.isPreview && (
              <Tag tagVariant="info">{t("inactive")}</Tag>
            )}
        </Stack>
      );
    },
  },
  {
    align: "left",
    field: "uuid",
    headerName: t("uuid"),
    flex: 1,
    minWidth: 120,
    renderCell: (params: any) => {
      return <HoverCopyText text={params.row.uuid} noWrap />;
    },
  },
  {
    align: "left",
    field: "activationDate",
    headerName: t("activation_date"),
    width: 150,
  },
  {
    align: "left",
    width: 120,
    sortable: false,
    field: "userRate",
    hide: !hasAnalytics,
    headerName: t("users_over_exposed"),
    renderCell: (params: any) => {
      return <>{params.row.userRate()}</>;
    },
  },
  {
    align: "left",
    width: 120,
    sortable: false,
    field: "weightInCa",
    hide: !hasAnalytics,
    headerName: t("revenues_from_exposed"),
    renderCell: (params: any) => {
      return <>{params.row.weightInCa()}</>;
    },
  },
  {
    align: "left",
    width: 120,
    field: "impact",
    sortable: false,
    hide: !hasAnalytics,
    headerName: t("revenues_per_users_over_revenues_per_exposed"),
    renderCell: (params: any) => {
      return <>{params.row.impact()}</>;
    },
  },
  {
    align: "right",
    field: "actions",
    headerName: "",
    width: 50,
    sortable: false,
    renderCell: (params: any) => {
      return (
        <>
          <Box
            sx={{ cursor: "pointer", "& svg": { width: 16, height: 16 } }}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
              setIdOfMenuOpen(params.row.id);
            }}
          >
            <MoreVerticalOutlinedIcon
              color={theme.customPalette.grey.default}
            />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && idOfMenuOpen === params.row.id}
            onClose={() => {
              setAnchorEl(null);
              setIdOfMenuOpen("");
            }}
          >
            <HasEditingRoleWrapper>
              {params.row.canEdit && (
                <MenuItem
                  onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation();
                    params.row.edit();
                  }}
                  disableRipple
                  sx={{ gap: 1, "& svg": { width: 16, height: 16 } }}
                >
                  <EditIcon color={theme.customPalette.grey.default} />
                  <Text>{t("configure")}</Text>
                </MenuItem>
              )}
              {params.row.canEdit && (
                <MenuItem
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorEl(null);
                    setIdOfMenuOpen("");
                    params.row.duplicate();
                  }}
                  disableRipple
                  sx={{ gap: 1, "& svg": { width: 16, height: 16 } }}
                >
                  <DuplicateIcon color={theme.customPalette.grey.default} />
                  <Text>{t("duplicate")}</Text>
                </MenuItem>
              )}
              {params.row.canDelete && (
                <MenuItem
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorEl(null);
                    setIdOfMenuOpen("");
                    params.row.delete();
                  }}
                  disableRipple
                  sx={{ gap: 1, "& svg": { width: 16, height: 16 } }}
                >
                  <DeleteIcon color={theme.customPalette.grey.default} />
                  <Text>{t("delete")}</Text>
                </MenuItem>
              )}
            </HasEditingRoleWrapper>
            {hasAnalytics && params.row.canReport && (
              <MenuItem
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                  params.row.report();
                }}
                disableRipple
                sx={{ gap: 1, "& svg": { width: 16, height: 16 } }}
              >
                <ReportIcon color={theme.customPalette.grey.default} />
                <Text>{t("report")}</Text>
              </MenuItem>
            )}
            {params.row.previewLink && (
              <MenuItem
                onClick={(event: React.SyntheticEvent) => {
                  event.stopPropagation();
                  window.open(params.row.previewLink);
                }}
                disableRipple
                sx={{ gap: 1, "& svg": { width: 16, height: 16 } }}
              >
                <VisibilityIcon color={theme.customPalette.grey.default} />
                <Text>{t("preview")}</Text>
              </MenuItem>
            )}
            {devMode && (
              <MenuItem
                onClick={(event: React.SyntheticEvent) => {
                  event.stopPropagation();
                  setSelectedExperience(params.row);
                  setAnchorEl(null);
                  setIdOfMenuOpen("");
                }}
                disableRipple
                sx={{ gap: 1, "& svg": { width: 16, height: 16 } }}
              >
                <SettingsIcon color={theme.customPalette.grey.default} />
                <Text>{t("settings")}</Text>
              </MenuItem>
            )}
          </Menu>
        </>
      );
    },
  },
];
