export enum VARIABLE_TYPE_ENUM {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  ITEM_ID = "ITEM_ID",
  CATEGORY_ID = "CATEGORY_ID",
  ARRAY_ITEM_ID = "ARRAY_ITEM_ID",
  ARRAY_CATEGORY_ID = "ARRAY_CATEGORY_ID",
  USER = "USER",
}

export const VARIABLE_NEED_PROPERTY_OF_DATASET: Dic<string> = {
  [VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID]: "catalog",
  [VARIABLE_TYPE_ENUM.ITEM_ID]: "catalog",
  [VARIABLE_TYPE_ENUM.USER]: "user",
};
