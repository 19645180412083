import dayjs, { Dayjs } from "dayjs";

export const getStartDate = (deployedAt: Dayjs) => {
  const startDate = dayjs(deployedAt).isBefore(dayjs().subtract(32, "days"))
    ? dayjs().subtract(32, "days").format("YYYY-MM-DD")
    : dayjs(deployedAt).format("YYYY-MM-DD");
  return startDate;
};

export const getEndDate = () =>
  dayjs().subtract(2, "days").add(1, "day").format("YYYY-MM-DD");

export const compareExperiences = (
  experienceA: Dic<any>,
  experienceB: Dic<any>
) => {
  if (
    experienceA.isActive ||
    (experienceA.isPreview && !experienceB.isActive)
  ) {
    return -1;
  } else if (
    experienceB.isActive ||
    (experienceB.isPreview && !experienceA.isActive && !experienceA.isPreview)
  ) {
    return 1;
  }
  return 0;
};
