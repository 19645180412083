import { createTheme } from "@mui/material/styles";
// When using TypeScript 4.x and above
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

const theme = {
  palette: {
    mode: "dark",
    overrides: {
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      },
    },
  },

  customPalette: {
    mode: "dark",
    grey: {
      disabled: "#9D99AD",
      default: "#FAF9FA",
      light: "#D6D4DD",
      white: "#18094E",
      lighter: "#18094E",
      infoBubble: "#FAF9FA",
    },
    bluePurple: {
      slighty: "#310556",
      lighter: "#133A71",
      light: "#FF33FF",
      main: "#FF33FF",
      dark: "#00FFFF",
      darker: "#292145",
    },
    pink: {
      dark: "#FF33FF",
      white: "#310556",
    },
    mustard: {
      main: "#FFAA00",
      light: "#362339",
    },
    citrus: {
      lighter: "#354000",
      light: "#556600",
      main: "#9AB804",
    },
    error: {
      main: "#FF4766",
      complementary: "#330534",
    },
    warning: {
      main: "#FFAA00",
      complementary: "#362339",
    },
    info: {
      main: "#33CCFF",
      complementary: "#172659",
    },
    success: {
      main: "#40FF00",
      complementary: "#203A3E",
    },
    others: {
      background: "#0E052E",
      layer_1: "#13073E",
      layer_2: "#18094E",
    },
    white: {
      main: "#13073E",
    },
    common: {
      white: "#13073E",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: { fontFamily: "Poppins", fontSize: "1.75rem !important" },
    h2: { fontFamily: "Poppins", fontSize: "1.5rem !important" },
    h3: { fontFamily: "Poppins", fontSize: "1rem !important" },
    h4: { fontFamily: "Poppins", textDecoration: "line-through" },
    h5: { fontFamily: "Poppins", textDecoration: "line-through" },
    h6: { fontFamily: "Poppins", textDecoration: "line-through" },
    subtitle1: {
      fontFamily: "Poppins",
      fontSize: "0.9375rem !important",
      fontWeight: 700,
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontWeight: 700,
      textDecoration: "line-through",
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: "1rem !important",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "0.875rem !important",
      fontWeight: 400,
    },
    caption: {
      fontFamily: "Poppins",
      fontSize: "0.75rem !important",
      fontWeight: 400,
    },
    button: {
      fontWeight: 600,
      fontFamily: "Poppins",
    },
  },
} as const;

export type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key];
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module "@mui/material/styles/createTheme" {
  interface Theme extends CustomTheme {
    customPalette: any;
  }
  interface ThemeOptions extends CustomTheme {
    customPalette?: any;
  }
}

export default createTheme(theme);
