import { useViewport } from "@hooks";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { IBtn } from "./types";
import { getColors, getStartIcon, getStyle } from "./utils";

const Button: React.FC<IBtn> = ({
  children,
  variant,
  disabled,
  startIcon,
  endIcon,
  loading,
  customStyle,
  btnVariant,
  startIconHover,
  ...rest
}) => {
  const { isMobile } = useViewport();

  const theme = useTheme();
  const isDisabled = !!(disabled || loading);
  const [isHover, setIsHover] = useState(false);

  const icons = {
    start: startIcon,
    startHover: startIconHover ?? startIcon,
    end: endIcon,
    disabled: null,
  };

  return (
    <LoadingButton
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      data-testid="btnTestId"
      startIcon={icons[getStartIcon(isHover, isDisabled) ?? "disabled"]}
      endIcon={isDisabled ? null : endIcon}
      variant={variant}
      disabled={isDisabled}
      size={isMobile ? "small" : "medium"}
      loading={loading}
      sx={{
        ...getStyle(variant),
        ...getColors(btnVariant, isDisabled)(theme),
        borderRadius: 1,
        fontWeight: 700,
        px: 3,
        height: 40,
        fontSize: "0.80rem",
        ...customStyle,
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

Button.defaultProps = {
  variant: "contained",
  disabled: false,
  startIcon: undefined,
  endIcon: undefined,
  loading: false,
  customStyle: {},
  btnVariant: "secondary",
  children: undefined,
};

export default Button;
