import { combineEpics, Epic } from "redux-observable";
import * as Sentry from "@sentry/browser";
import { mergeMap, filter, map, catchError } from "rxjs/operators";
import { of, from, pipe } from "rxjs";
import { isOfType } from "typesafe-actions";
import axios from "axios";
import { gql } from "@apollo/client/core";
import { client } from "../../../index";
import {
  LOGGING_IN,
  ERROR,
  GET_ACCOUNT_AND_SITE_ID,
  ERROR_LOGIN,
  REGISTERING,
  REGISTERING_INVITE,
  LOGGING_IN_INVITE,
  LOGGING_IN_WITH_GOOGLE,
} from "../actions/action-types";
import {
  userLoggedIn,
  userGetAccountAndSiteId,
  userMustVerifyEmail,
} from "@actions";
import { navigatorUtils } from "@utils";
import { potionsLocalStorage } from "@localStorage";

const errorMessageTranslationKey: Dic<string> = {
  401: "password_email_incorrect",
  498: "expired_token",
};

const GET_ACCOUNTS_AND_SITES = gql`
  query companies {
    companies(first: 1) {
      edges {
        node {
          id
          name
          theme
          sites {
            edges {
              node {
                id
                hasAbtest
                hasSkuReport
                hasMonitor
                hasAlgorithmBuilder
                name
                showPages
                catalogs {
                  edges {
                    node {
                      id
                      name
                      settings
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const userGetAccountAndSiteIdEpic: Epic<any, any, any> = (action$: any) =>
  action$.pipe(
    filter(isOfType(GET_ACCOUNT_AND_SITE_ID)),
    mergeMap((action: any) =>
      from(
        client.query({
          query: GET_ACCOUNTS_AND_SITES,
          variables: {},
        })
      ).pipe(
        map((response: any) => {
          const accountId = response?.data?.companies?.edges?.[0]?.node?.id;
          const accountName = response?.data?.companies?.edges?.[0]?.node?.name;
          const theme = response?.data?.companies?.edges?.[0]?.node?.theme;
          const siteId =
            response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]?.node
              ?.id;
          const siteName =
            response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]?.node
              ?.name;
          const catalogId =
            response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]?.node
              ?.catalogs?.edges?.[0]?.node?.id ?? 0;
          const user = potionsLocalStorage.get("user")
            ? { ...potionsLocalStorage.get("user"), theme }
            : {
                email: "",
                profilePicture: "",
                langue: navigatorUtils.getNavigatorLanguage() ?? "en",
              };
          const hasPages =
            response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]?.node
              ?.showPages;
          const hasAlgorithmBuilder =
            !!response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]
              ?.node?.hasAlgorithmBuilder;
          potionsLocalStorage.set("catalogId", parseInt(catalogId ?? "0", 10));
          potionsLocalStorage.set("accountName", accountName);
          potionsLocalStorage.set("accountTheme", theme);
          potionsLocalStorage.set("accountId", accountId);
          potionsLocalStorage.set("siteId", siteId);
          potionsLocalStorage.set("siteName", siteName);
          potionsLocalStorage.set("hasAlgorithmBuilder", hasAlgorithmBuilder);
          const hasAnalytics =
            !!response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]
              ?.node?.hasMonitor;
          const hasAbtest =
            !!response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]
              ?.node?.hasAbtest;
          const hasSkuReport =
            !!response?.data?.companies?.edges?.[0]?.node?.sites?.edges?.[0]
              ?.node?.hasSkuReport;
          return userLoggedIn(
            { ...user },
            accountId,
            siteId,
            theme,
            accountName,
            siteName,
            catalogId,
            hasPages,
            hasAnalytics,
            hasAbtest,
            hasSkuReport
          );
        }),
        catchError((error: any) => {
          if (process.env.REACT_APP_ENV === "prod") {
            Sentry.init({
              dsn: "https://062d15e0cd424e75aa66a24aff91162c@o435032.ingest.sentry.io/4504049898749952",
            } as Sentry.BrowserOptions);
            Sentry.captureException(`Error getting sites after login ${error}`);
          }
          return of({
            type: ERROR_LOGIN,
            payload: {
              errorLocation: "login",
              errorMessage: "error_occurred",
            },
            error: true,
          });
        })
      )
    )
  );

const loginEpic: Epic<any, any, any> = (action$: any) =>
  action$.pipe(
    filter(isOfType(LOGGING_IN)),
    mergeMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/auth/token`,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: action.payload.encodedForm,
        })
      ).pipe(
        map((response: any) => {
          potionsLocalStorage.set("token", response.data.access_token);
          potionsLocalStorage.set("refreshToken", response.data.refresh_token);
          return userGetAccountAndSiteId();
        }),

        catchError((error: any) => {
          if (process.env.REACT_APP_ENV === "prod") {
            Sentry.init({
              dsn: "https://062d15e0cd424e75aa66a24aff91162c@o435032.ingest.sentry.io/4504049898749952",
            } as Sentry.BrowserOptions);
            Sentry.captureException(`Error while trying to login ${error}`);
          }
          return of({
            type: ERROR_LOGIN,
            payload: {
              errorLocation: "login",
              errorMessage:
                error.response.data.detail === "Incorrect username or password"
                  ? "password_email_incorrect"
                  : "server_error",
            },
            error: true,
          });
        })
      )
    )
  );

const googleLoginEpic: Epic<any, any, any> = (action$: any) =>
  action$.pipe(
    filter(isOfType(LOGGING_IN_WITH_GOOGLE)),
    pipe(
      map(() => {
        return userGetAccountAndSiteId();
      }),
      catchError((error: any) => {
        if (process.env.REACT_APP_ENV === "prod") {
          Sentry.init({
            dsn: "https://062d15e0cd424e75aa66a24aff91162c@o435032.ingest.sentry.io/4504049898749952",
          } as Sentry.BrowserOptions);
          Sentry.captureException(`Error while trying to login ${error}`);
        }
        return of({
          type: ERROR_LOGIN,
          payload: {
            errorLocation: "login",
            errorMessage:
              error.response.data.detail === "Incorrect username or password"
                ? "password_email_incorrect"
                : "server_error",
          },
          error: true,
        });
      })
    )
  );

const invitedLoginEpic: Epic<any, any, any> = (action$: any) =>
  action$.pipe(
    filter(isOfType(LOGGING_IN_INVITE)),
    mergeMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/auth/login_invitation?token=${action.payload.token}`,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: action.payload.encodedForm,
        })
      ).pipe(
        map((response: any) => {
          potionsLocalStorage.set("token", response.data.access_token);
          potionsLocalStorage.set("refreshToken", response.data.refresh_token);
          return userGetAccountAndSiteId();
        }),
        catchError((error: any) =>
          of({
            type: ERROR_LOGIN,
            payload: {
              errorLocation: "login",
              errorMessage:
                errorMessageTranslationKey[error.response.status] ??
                "server_error",
            },
            error: true,
          })
        )
      )
    )
  );

const signupEpic: Epic<any, any, any> = (action$: any) =>
  action$.pipe(
    filter(isOfType(REGISTERING)),
    mergeMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/auth/signup`,
          headers: {
            "Content-Type": "application/json",
          },
          data: action.payload.data,
        })
      ).pipe(
        map((response: any) => {
          return userMustVerifyEmail();
        }),

        catchError((error: any) =>
          of({
            type: ERROR_LOGIN,
            payload: {
              errorLocation: "signup",
              errorMessage: "server_error",
            },
            error: true,
          })
        )
      )
    )
  );

const invitedSignupEpic: Epic<any, any, any> = (action$: any) =>
  action$.pipe(
    filter(isOfType(REGISTERING_INVITE)),
    mergeMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/auth/signup_invitation?token=${action.payload.token}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: action.payload.data,
        })
      ).pipe(
        map((response: any) => {
          potionsLocalStorage.set("token", response.data.access_token);
          potionsLocalStorage.set("refreshToken", response.data.refresh_token);
          return userGetAccountAndSiteId();
        }),

        catchError((error: any) =>
          of({
            type: ERROR_LOGIN,
            payload: {
              errorLocation: "signup",
              errorMessage:
                errorMessageTranslationKey[error.response.status] ??
                "server_error",
            },
            error: true,
          })
        )
      )
    )
  );

export default combineEpics(
  loginEpic,
  userGetAccountAndSiteIdEpic,
  signupEpic,
  invitedSignupEpic,
  invitedLoginEpic,
  googleLoginEpic
);
