/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField, IconButton, Text } from "../../../includes";
import { IFloatInput } from "./types";
import { DEFAULT_INPUT_WIDTH, FONT_SIZE } from "./enums";
import { arr } from "./helpers";

const FloatInput: React.FC<IFloatInput> = ({
  value,
  setValue,
  variant,
  max,
  disabled,
  type = "float",
}) => {
  const theme = useTheme();

  const maxValue = max ?? 999999;

  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  useEffect(() => {
    if (value) setInputWidth((arr(value).length + 1) * (FONT_SIZE - 2));
  }, [value]);

  return (
    <TextField
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ width: 33, height: 33 }}
              disabled={disabled}
              onClick={() => {
                if (value < maxValue) {
                  setValue(
                    Math.round((value + (type === "float" ? 0.1 : 1)) * 10) / 10
                  );
                }
              }}
            >
              <Text variant="body1">+</Text>
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              sx={{ width: 33, height: 33 }}
              disabled={disabled}
              onClick={() => {
                if (value > 0) {
                  setValue(
                    Math.round((value - (type === "float" ? 0.1 : 1)) * 10) / 10
                  );
                }
              }}
            >
              <Text variant="body1">-</Text>
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="small"
      sx={{
        borderRadius: 3,
        "&.MuiInputBase-input": {
          textAlign: "center",
          border: `1px solid ${theme.customPalette.others.background}`,
        },
        "& fieldset": {
          borderRadius: "10px",
          border: "none",
        },
        border: `1px solid ${theme.customPalette.others.background}`,
        background: theme.customPalette.others.layer_2,
        fontSize: FONT_SIZE,
        "& input": {
          flex: 1,
        },
      }}
      type="number"
      value={value}
      variant={variant}
      inputProps={{
        style: { textAlign: "center", width: `${inputWidth}px` },
      }}
      onChange={(event) => {
        const newValue = parseFloat(event.target.value);
        if (newValue >= 0 && newValue <= maxValue) setValue(newValue);
        else if (newValue < 0) {
          setValue(0);
        } else if (newValue > maxValue) setValue(maxValue);
      }}
    />
  );
};

FloatInput.defaultProps = {
  variant: "outlined",
  max: Infinity,
  disabled: false,
};

export default FloatInput;
