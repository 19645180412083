import { Stack, Fade } from "@mui/material";
import { useSelector as useXstateSelector } from "@xstate/react";
import { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store";
import { Box, Text, Button } from "@includes";
import { client } from "@/services/graphql/apolloConfig";
import { useSharedErrors } from "../../hooks/useError";
import { arrayUtils, graphqlUtils } from "@/utils";
import { useConfigureMerchandizing } from "../../hooks/useConfigureMerchandizing";
import { addRun, sendNotification } from "@actions";
import { SaveIcon } from "@/assets/icons";
import {
  CREATE_EXPERIENCE,
  CREATE_PAGE,
  UPDATE_EXPERIENCE,
  UPDATE_PAGE,
} from "./requests";

const categoriesToSaveSelector = (state: any) => state.context.categoriesToSave;
const serverDataSelector = (state: any) => state.context.serverData;
const idSelector = (state: any) => state.context.id;

const experienceInputSelector = (state: any) => state.context.experienceInput;
const currentPushedProductsSelector = (state: any) =>
  state.context.currentPushedProducts;

interface ISave {
  refreshCategories: () => Promise<string>;
}

const SavePage: React.FC<ISave> = ({ refreshCategories }) => {
  const dispatch = useDispatch();

  const { t }: i18translateType = useTranslation();

  const { setErrorCollection, setErrorCategories } = useSharedErrors();

  const experienceServices = useConfigureMerchandizing();

  const { send } = experienceServices.experienceService;

  const currentPushedProducts = useXstateSelector(
    experienceServices.experienceService,
    currentPushedProductsSelector
  );

  const experienceId = useXstateSelector(
    experienceServices.experienceService,
    idSelector
  );

  const experienceInput = useXstateSelector(
    experienceServices.experienceService,
    experienceInputSelector
  );

  const { includedCategories, excludedCategories, collectionId, name } =
    experienceInput;

  const categoriesToSave = useXstateSelector(
    experienceServices.experienceService,
    categoriesToSaveSelector
  );
  const serverData = useXstateSelector(
    experienceServices.experienceService,
    serverDataSelector
  );
  const hasUpdatedIncludedCategories = !arrayUtils.arrayEquals(
    includedCategories,
    serverData.includedCategories
  );
  const hasUpdatedExcludedCategories = !arrayUtils.arrayEquals(
    excludedCategories,
    serverData.excludedCategories
  );
  const hasChangedCollectionId = collectionId !== serverData.collectionId;

  const hasChangedName = name !== serverData.name;

  const needUpdateExperience =
    hasChangedCollectionId ||
    hasUpdatedExcludedCategories ||
    hasUpdatedIncludedCategories ||
    hasChangedName;

  const needUpdateOrCreatePage = categoriesToSave.length > 0;

  const canDeploy = needUpdateExperience || needUpdateOrCreatePage;

  const siteId = useSelector((state: RootState) => state.site.siteId);

  const history = useHistory();

  const [status, setStatus] = useState("");

  const handleSavePage = (category: Dic<any>, notify: boolean) => {
    const { id, pageId } = category;
    return new Promise((resolve) => {
      client
        .mutate({
          mutation: UPDATE_PAGE,
          variables: {
            page: {
              settings: {
                pushed_products: { ...currentPushedProducts[id] },
              },
            },
            pageId,
          },
        })
        .then((res: any) => {
          if (notify) {
            const run = {
              id: res.data.updatePage.runId,
              notify: true,
              alert: {
                title: `${t("pages_deployed")}`,
                text: t("pages_deployed_description"),
                severity: "success",
                name: "deployed_pages",
              },
            };
            if (run.id) dispatch(addRun(run));
          }
          resolve("success");
        })
        .catch(() => setStatus("error"));
    });
  };

  const handleCreatePage = (
    category: Dic<any>,
    createdExperienceId: string,
    notify: boolean
  ) => {
    const { id, title } = category;
    return new Promise((resolve) => {
      client
        .mutate({
          mutation: CREATE_PAGE,
          variables: {
            page: {
              settings: {
                pushed_products: currentPushedProducts[id],
              },
              collectionId: parseInt(experienceInput.collectionId, 10),
              categoryId: id,
              name: category.title,
            },
            experienceId: createdExperienceId,
          },
        })
        .then((res: any) => {
          if (notify) {
            const run = {
              id: res.data.createPage.runId,
              notify: true,
              alert: {
                title: `${t("pages_deployed")}`,
                text: t("pages_deployed_description"),
                severity: "success",
                name: "deployed_pages",
              },
            };
            if (run.id) dispatch(addRun(run));
          }
          resolve("success");
        })
        .catch(() => setStatus("error"));
    });
  };

  const saveCategoryManipulation =
    (_experienceId: string, nbCategoryToSave: number) =>
    (category: Dic<any>, index: number) => {
      const { pageId } = category;
      return new Promise((resolve) => {
        if (pageId)
          handleSavePage(category, nbCategoryToSave === index + 1).then(
            resolve
          );
        else
          handleCreatePage(
            category,
            _experienceId,
            nbCategoryToSave === index + 1
          ).then(resolve);
      });
    };

  const saveAllCategoriesManipulations = async (_experienceId: string) => {
    return new Promise((resolve, reject) => {
      if (categoriesToSave?.length === 0) resolve("success");
      dispatch(
        sendNotification(
          "",
          t("deploying_changes"),
          t("deploying_changes_description_1"),
          "info"
        )
      );
      Promise.all(
        categoriesToSave.map(
          saveCategoryManipulation(_experienceId, categoriesToSave?.length)
        )
      ).then(resolve);
    }).catch(() => setStatus("error"));
  };

  const handleSave = () => {
    setStatus("loading");

    if (needUpdateExperience)
      graphqlUtils
        .mutate(UPDATE_EXPERIENCE, {
          id: experienceId,
          experience: {
            ...experienceInput,
          },
        })
        .then((res: any) => {
          saveAllCategoriesManipulations(experienceId)
            .then(() => {
              refreshCategories().then(() => {
                const run = {
                  id: res.data.updateExperience.runId,
                  notify: true,
                  alert: {
                    title: `${res.data.updateExperience.name} ${t(
                      "is_deployed"
                    )}`,
                    text: t("is_deployed_description"),
                    severity: "Success",
                    name: "deployed_experience",
                  },
                };
                if (run.id) dispatch(addRun(run));
                dispatch(
                  sendNotification(
                    "",
                    t("deploying_changes"),
                    t("deploying_changes_description_1"),
                    "info"
                  )
                );
                send({
                  type: "REFRESH",
                  data: { id: experienceId },
                });
                setStatus("");
              });
            })
            .catch(() => {
              setStatus("error");
            });
        })
        .catch(() => {
          setStatus("error");
        });
    else
      saveAllCategoriesManipulations(experienceId)
        .then(() => {
          refreshCategories().then(() => {
            send({
              type: "REFRESH",
              data: { id: experienceId },
            });
            setStatus("");
          });
        })
        .catch(() => {
          setStatus("error");
        });
  };

  const handleCreate = () => {
    setStatus("loading");
    graphqlUtils
      .mutate(CREATE_EXPERIENCE, {
        siteId: siteId,
        experience: {
          ...experienceInput,
        },
      })
      .then((res: any) => {
        saveAllCategoriesManipulations(res.data.createExperience.id)
          .then(() => {
            const run = {
              id: res.data.createExperience.runId,
              notify: true,
              alert: {
                title: `${res.data.createExperience.name} ${t("is_deployed")}`,
                text: t("is_deployed_description"),
                severity: "Success",
                name: "deployed_experience",
              },
            };
            if (run.id) dispatch(addRun(run));
            dispatch(
              sendNotification(
                "",
                t("deploying_changes"),
                t("deploying_changes_description_1"),
                "info"
              )
            );
            setStatus("");
            history.push(`${res.data.createExperience.id}`);
          })
          .catch(() => setStatus("error"));
      })
      .catch((e: any) => {
        setStatus("error");
      });
  };

  const handleSaveOrCreate = () => {
    if (!experienceInput.collectionId) {
      setErrorCollection();
    } else if (
      (!experienceInput.includedCategories ||
        experienceInput.includedCategories?.length === 0) &&
      (!experienceInput.excludedCategories ||
        experienceInput.excludedCategories?.length === 0)
    ) {
      setErrorCategories();
    } else {
      if (!!experienceId) handleSave();
      else handleCreate();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "end",
      }}
    >
      <Stack spacing={1}>
        <Fade in={canDeploy}>
          <div>
            <Button
              id="activate"
              startIcon={<SaveIcon />}
              onClick={handleSaveOrCreate}
              loading={status === "loading"}
            >
              {t("deploy")}
            </Button>
          </div>
        </Fade>
        {status === "error" && (
          <Text textType="error">{t("default_error")}</Text>
        )}
      </Stack>
    </Box>
  );
};

export default SavePage;
