import { gql } from "@apollo/client";

export const UPDATE_EXPERIENCE = gql`
  mutation updateExperience(
    $id: ExperienceID!
    $experience: ExperienceUpdateInput!
  ) {
    updateExperience(experienceId: $id, experience: $experience) {
      id
    }
  }
`;
