import { AddIcon } from "@/assets/icons";
import { Button, Text } from "@/includes";
import { navigatorUtils } from "@/utils";
import { Box, Stack, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { INoRowsOverlay } from "./types";

const NoRowsOverlay: React.FC<INoRowsOverlay> = ({
  canCreate,
  handleEdit,
  requiredIntegrations,
  getExperiences,
}) => {
  const history = useHistory();
  const { accountId, siteId } = useParams<UrlParams>();
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();

  const requireIntegrationToCreate = requiredIntegrations.length > 0;
  const showIntegrationRequired = requireIntegrationToCreate && !canCreate;

  const requestDemo = () => {
    window.open("https://get-potions.com/demo/");
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
        height: "100%",
        zIndex: 80,
        position: "absolute",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Text>{t("no_experience")}</Text>
      {showIntegrationRequired ? (
        <>
          <Text>{t("tools_required")}</Text>
          <Box component="ul">
            {requiredIntegrations.map((integration: string) => (
              <Box
                key={integration}
                component="li"
                sx={{ color: theme.customPalette.grey.default }}
              >
                {integration}
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <Text variant="body2" textType="secondary" centerText>
          {t("no_experience_description_1")}
          <br />
          {canCreate ? t("no_experience_description_2") : ""}
        </Text>
      )}
      <Stack direction="row" alignItems="center" spacing={2}>
        {canCreate && (
          <Button
            btnVariant="primary"
            id="create"
            onClick={navigatorUtils.goTo(
              `/${accountId}/${siteId}/recommendation_builder`,
              history
            )}
            startIcon={<AddIcon />}
          >
            {t("new_experience")}
          </Button>
        )}
        {showIntegrationRequired && (
          <Button
            btnVariant="outline"
            id="create"
            onClick={navigatorUtils.goTo(
              `/${accountId}/${siteId}/settings/integrations`,
              history
            )}
            startIcon={<AddIcon />}
          >
            {t("connect_my_tools")}
          </Button>
        )}
        {!canCreate && (
          <Button onClick={requestDemo} id="request-demo">
            {t("request_demo")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default NoRowsOverlay;
