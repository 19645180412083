export const EXPERIENCES_MAP: Dic<string> = {
  MULTIPLY: "Personnalised recommendation -",
  SWITCH: "Targeted pop-up -",
  PROPULSE: "Last session recovery -",
  FLASHBACK: "Recently viewed products -",
  CONNECT: "Automatic authentication -",
  "All personnalised recommandations": "All personnalised recommandations",
  "All targeted pop-ups": "All targeted pop-ups",
  "All last session recoveries": "All last session recoveries",
  "All recently viewed products": "All recently viewed products",
};

export const VERSION = "v-2.2";

export const getNoRowsTexts = (
  list: string | undefined,
  t: (text: string) => string
) => {
  let [title, subtitle, subtitle2] = ["", t("no_data"), ""];
  if (list === "rules") {
    [title, subtitle, subtitle2] = [
      t("empty_list"),
      t("no_composed_rules"),
      t("add_rule_empty_list"),
    ];
  }
  if (list === "products") {
    [title, subtitle, subtitle2] = [
      t("empty_list"),
      t("empty_manual_rule"),
      t("empty_rule_add_product"),
    ];
  }
  if (list === "run result") {
    [title, subtitle, subtitle2] = [
      t("no_products"),
      t("no_product_result"),
      t("error_no_product"),
    ];
  }

  return { title, subtitle, subtitle2 };
};

export const getLoadingTexts = (
  list: string | undefined,
  t: (text: string) => string
) => {
  let [title, subtitle, subtitle2] = [
    `${t("loading")}...`,
    t("take_while"),
    "",
  ];
  if (!list) return { title, subtitle, subtitle2 };
  if (list === "rules") {
    [title, subtitle, subtitle2] = [
      `${t("loading")}...`,
      t("loading_rules"),
      t("take_while"),
    ];
  }
  if (["products", "catalog"].includes(list)) {
    [title, subtitle, subtitle2] = [
      `${t("loading")}...`,
      t("loading_products"),
      t("take_while"),
    ];
  }
  if (list === "run result") {
    [title, subtitle, subtitle2] = [
      `${t("loading")}...`,
      t("loading_rule_result"),
      t("take_while"),
    ];
  }

  return { title, subtitle, subtitle2 };
};

const GET_MULTIPLY_TEXTS = (t: (text: string) => string) => ({
  seen: t("number_display"),
  used: t("number_use"),
  used0: t("number_visit_recommended_product"),
  used1: t("number_recommended_products_added"),
  used0Percent: t("rate_visit_recommended_product"),
  used1Percent: t("share_recommended_product_added"),
  usedPercent: t("utilization_rate"),
});

const GET_SWITCH_TEXTS = (t: (text: string) => string) => ({
  seen: t("number_display"),
  used: t("number_use"),
  used1: t("number_prefilling"),
  used1Percent: t("rate_prefill"),
  usedPercent: t("utilization_rate"),
});

const GET_PROPULSE_TEXTS = (t: (text: string) => string) => ({
  seen: t("number_display"),
  used: t("number_use"),
  used1: t("number_closure"),
  used1Percent: t("share_closure"),
  usedPercent: t("utilization_rate"),
});

export const getEventName: any = (
  type: string,
  t: (text: string) => string
) => {
  if (type === "MULTIPLY") {
    return GET_MULTIPLY_TEXTS(t);
  }
  if (type === "FLASHBACK") {
    return GET_MULTIPLY_TEXTS(t);
  }
  if (type === "PROPULSE") {
    return GET_PROPULSE_TEXTS(t);
  }
  if (type === "SWITCH") return GET_SWITCH_TEXTS(t);
};

export const FILTERED_PRODUCT_PROPS = [
  "price",
  "stroke_price",
  "discount_rate",
  "category",
];
