import { IIcon } from "./types";

const Edit: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1308 0.828741C17.7599 0.895845 17.3985 1.05084 17.1041 1.26909C16.9803 1.36089 14.6069 3.70605 10.263 8.02874C4.35276 13.9102 3.60334 14.6641 3.53806 14.7939C3.44964 14.9697 1.45181 22.0905 1.43275 22.2977C1.4016 22.6369 1.61019 22.9727 1.95149 23.1327C2.06297 23.1849 2.11971 23.1957 2.27837 23.1948C2.45671 23.1939 2.72748 23.1217 6.08237 22.1817C8.51487 21.5002 9.74203 21.1457 9.84036 21.0963C9.97006 21.0311 10.7248 20.2808 16.6023 14.3744C20.2411 10.7178 23.2733 7.6535 23.3405 7.56494C23.4919 7.36555 23.6805 6.98285 23.7451 6.74393C23.8954 6.18782 23.8381 5.54556 23.5932 5.04252C23.4038 4.65317 23.343 4.58539 21.7321 2.96765C20.8797 2.11171 20.1202 1.3631 20.0442 1.30413C19.5175 0.895245 18.7783 0.711597 18.1308 0.828741ZM18.2384 2.58924C18.1095 2.65123 17.4105 3.339 11.5784 9.14237L5.06237 15.6262L4.29823 18.351C3.87797 19.8495 3.53962 21.0812 3.54636 21.0879C3.55311 21.0947 4.78469 20.7563 6.2832 20.3361L9.00778 19.5719L15.4901 13.0559C20.9678 7.54982 21.9825 6.51948 22.0374 6.40793C22.0919 6.29719 22.1022 6.24501 22.1017 6.08393C22.1011 5.92315 22.0905 5.87186 22.0366 5.76857C21.9883 5.67605 21.6022 5.27246 20.4935 4.15555C18.8297 2.47939 18.8778 2.5199 18.5525 2.51995C18.4142 2.51995 18.3554 2.53293 18.2384 2.58924Z"
        fill={color}
      />
    </svg>
  );
};

Edit.defaultProps = {
  color: "transparent",
};

export default Edit;
