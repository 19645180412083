import useProductsColumns from "@/hooks/useProductsColumns";
import { RootState } from "@/services/redux/store";
import { usePersistedState, useProducts } from "@hooks";
import { DataGrid, GridToolbar, SwitchIcon } from "@includes";
import { Box, CircularProgress, Tab, Tabs, useTheme } from "@mui/material";
import {
  GridCellEditStopParams,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DataGridClientPagination from "../DataGridClientPagination";
import productsColumns from "./columns";
import Products from "./components/Products";
import { a11yProps } from "./helpers";
import { IDraggableProductList } from "./types";

const DraggableProductList: React.FC<IDraggableProductList> = ({
  visibleFields,
  products,
  unpin,
  isLoading,
  list,
  pushProduct,
  pin,
  categoryId,
  pinnable,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const { getCatalogColumns, getInitialColumns } = useProductsColumns(currency);
  const [datagridColumns, setDatagridColumns] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = usePersistedState({
    key: "draggableProductsListPageSize-2",
    initialValue: 100,
  });
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [productPerRow, setProductPerRow] = usePersistedState({
    key: "productPerRow",
    initialValue: 6,
  });

  const [nbProductToShow, setNbProductToShow] = useState(30);

  const productToShow = useMemo(() => {
    if (products)
      return products?.map((p: any, index: number) => ({
        ...p,
        unpin: () => unpin(p?.id),
        pin: () => pin(p?.id, index + 1),
      }));
    return [];
  }, [products]);

  const dragAndDrop = (params: GridRowOrderChangeParams) => {
    const { oldIndex, targetIndex } = params;
    if (products[oldIndex]?.isPinned && products?.[targetIndex]?.isPinned) {
      pin(products[oldIndex]?.id, targetIndex + 1);
      pin(products[targetIndex]?.id, oldIndex + 1);
    } else pushProduct(products[oldIndex]?.id, targetIndex + 1);
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = usePersistedState({
    key: `merchandizing-${siteId}`,
    initialValue: {},
  });
  const columns = useMemo(() => {
    return productsColumns(theme, t, currency);
  }, [theme]);
  const catalogColumns = useSelector(
    (state: RootState) => state.catalog.catalogColumns
  );
  useEffect(() => {
    const additionnalsColumns = getCatalogColumns(catalogColumns, t);
    setDatagridColumns([...columns, ...additionnalsColumns]);
  }, [columns]);
  const [initialData, setInitialData] = useState<Dic<any>>({});

  useEffect(() => {
    if (!isEmpty(datagridColumns)) {
      setInitialData(getInitialColumns(datagridColumns));
    }
  }, [datagridColumns]);

  useEffect(() => {
    if (isEmpty(columnVisibilityModel) && !isEmpty(initialData)) {
      setColumnVisibilityModel(initialData);
    } else if (!isEmpty(initialData)) {
      setColumnVisibilityModel({ ...initialData, ...columnVisibilityModel });
    }
  }, [initialData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [categoryId]);

  const { getProductsInfos, isGettingProductInfos } = useProducts();
  const [productsToShowWithData, setProductsToShowWithData] = useState<
    Array<Dic<any>>
  >([]);

  useEffect(() => {
    if (!isEmpty(productToShow) && value === 1) {
      getProductsInfos(
        productToShow?.map((pdToShow: Dic<any>) => pdToShow.id)
      ).then((res: Dic<any>) => {
        setProductsToShowWithData(
          res.map((resProduct: Dic<any>, index: number) => {
            const associatedProduct =
              productToShow?.find(
                (pdToShow: Dic<any>) => pdToShow?.id === resProduct?.id
              ) ?? {};
            return {
              enrichedData: resProduct?.enrichedData,
              ...resProduct,
              isPinned: associatedProduct?.isPinned ?? false,
              pin: associatedProduct?.pin,
              unpin: associatedProduct?.unpin,
              index,
            };
          })
        );
      });
    }
  }, [productToShow, value]);

  const getTable = useMemo(() => {
    return (
      <DataGrid
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        pageSize={pageSize}
        currentPage={currentPage}
        paginationType="client"
        columns={datagridColumns}
        onRowOrderChange={dragAndDrop}
        rowReordering
        rowCount={products?.length}
        rows={productsToShowWithData ?? []}
        loading={isLoading || isGettingProductInfos}
        componentsProps={{
          pagination: {
            totalItems: products.length,
            pageSize,
            handleChangePageSize: setPageSize,
            currentPage,
            handleChangeCurrentPage: setCurrentPage,
          },
        }}
        components={{
          Pagination: DataGridClientPagination,
          Toolbar: GridToolbar,
        }}
        onCellEditCommit={(params: any) => {
          if (!!params?.value && params?.value >= 0)
            pushProduct(
              products[params?.row?.index]?.id,
              parseInt(params?.value) + 1
            );
        }}
        onCellEditStop={(params: GridCellEditStopParams, event: any) => {
          if (!!event?.target?.value && event.target.value >= 0)
            pushProduct(
              products[params?.row?.index]?.id,
              parseInt(event.target.value) + 1
            );
        }}
      />
    );
  }, [
    productsToShowWithData,
    currentPage,
    pageSize,
    columnVisibilityModel,
    isLoading,
    isGettingProductInfos,
  ]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiTabs-root": {
            minHeight: "unset",
          },
          "& .Mui-selected": {
            color: `${theme.customPalette.grey.default} !important`,
          },
          "& .MuiButtonBase-root": {
            minHeight: "30px",
            height: "fit-content",
            fontSize: "1rem",
            alignItems: "center",
            padding: "4px 16px 4px 0",
            textTransform: "capitalize",
          },
          "& .MuiTabs-flexContainer": {
            alignItems: "end",
            height: "100%",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="product list"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.customPalette.bluePurple.dark,
            },
          }}
        >
          <Tab
            label={t("preview_view")}
            {...a11yProps(0)}
            sx={{ textTransform: "unset", gap: "10px" }}
            icon={<SwitchIcon icon="previewlist" disabled={value !== 0} />}
            iconPosition="start"
          />
          <Tab
            label={t("table_view")}
            {...a11yProps(1)}
            sx={{ textTransform: "unset", gap: "10px" }}
            icon={<SwitchIcon icon="tableview" disabled={value !== 1} />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      {value === 1 && (
        <>{isEmpty(columnVisibilityModel) ? <CircularProgress /> : getTable}</>
      )}

      {value === 0 && (
        <Box sx={{ ml: "60px" }}>
          <Products
            categoryId={categoryId}
            setNbProductToShow={setNbProductToShow}
            nbProductToShow={nbProductToShow}
            isLoading={isLoading}
            list={list}
            products={products}
            productsPerRow={productPerRow}
            setProductsPerRow={setProductPerRow}
            unpin={unpin}
            pushProduct={pushProduct}
            pin={pin}
            pinnable={!!pinnable}
          />
        </Box>
      )}
    </Box>
  );
};

DraggableProductList.defaultProps = {
  pinnable: true,
  pin: () => null,
};

export default DraggableProductList;
