import { useViewport } from "@/hooks";
import { Box } from "@mui/material";
import { useState } from "react";
import Slider from "../../../../_Templates/Slider";
import LoadingOverlay from "../LoadingOverlay";
import NoRowsOverlay from "../NoRowsOverlay";
import ProductCard from "../ProductCard";
import { ISlide } from "./types";

const Slide: React.FC<ISlide> = ({
  isLoading,
  products,
  productPerRow,
  setProductPerRow,
  list,
  currency = "€",
}) => {
  const { isMobile } = useViewport();
  const [maxProductPerRow, setMaxProductPerRow] = useState(3);
  if (isLoading) {
    return <LoadingOverlay list={list} />;
  }
  if (products?.length === 0) return <NoRowsOverlay list={list} />;
  return (
    <Box>
      <Slider
        totalItems={products?.length}
        maxProductPerRow={maxProductPerRow}
        setMaxProductPerRow={setMaxProductPerRow}
      >
        {products?.map((product: Dic<any>) => (
          <ProductCard
            key={product?.id}
            product={product}
            productPerRow={maxProductPerRow}
            currency={currency}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default Slide;
