export const getTraduction = (
  t: (text: string, options?: Dic<any>) => string,
  keyTrad: string
) => {
  if (keyTrad.includes("_last_") && keyTrad.includes("_days")) {
    const splitKeyTrad = keyTrad.split("_");
    if (
      splitKeyTrad.length === 4 &&
      splitKeyTrad[1] === "last" &&
      splitKeyTrad[3] === "days"
    ) {
      return t("property_last_x_days", {
        property: t(splitKeyTrad[0]),
        number: splitKeyTrad[2],
      });
    }
  }
  return t(keyTrad);
};
