import { useMemo } from "react";
import { useLocation } from "react-router";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Collapse, Grid, IconButton, Stack, useTheme } from "@mui/material";
import { useSelector as useXstateSelector } from "@xstate/react";
import { Box } from "@mui/system";
import ExperienceSelector from "../../../ExperienceSelector";
import {
  Button,
  DateRangePicker,
  MultipleDeviceSelector,
  StyledGridItem,
  Text,
} from "@/includes";
import { useExperienceAnalytics } from "../../hooks/useExperienceAnalytics";
import { CloseIcon } from "@/assets/icons";
import { datesUtils } from "@/utils";
import { IHeader } from "./types";

const compareSelector = (state: any) => state.context.compare;
const compareWithSelector = (state: any) => state.context.compareWith;
const filtersSelector = (state: any) => state.context.filters;

const Header: React.FC<IHeader> = ({
  boxRef,
  experience,
  setExperience,
  loading,
  dateMin,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const experienceId = params.get("experienceId");

  const noComparison = params.get("noSelector");

  const experienceServices = useExperienceAnalytics();

  const { send } = experienceServices.experienceService;

  const compare = useXstateSelector(
    experienceServices.experienceService,
    compareSelector
  );

  const compareWith = useXstateSelector(
    experienceServices.experienceService,
    compareWithSelector
  );

  const filters = useXstateSelector(
    experienceServices.experienceService,
    filtersSelector
  );

  const { dateRange, devices } = filters;

  const availableDevices = useMemo(() => {
    let res = [];
    if (!experience) return [];
    if (experience.onMobile) res.push("mobile");
    if (experience.onDesktop) res.push("desktop");
    return res;
  }, [experience]);

  const availableDevicesForComparison = useMemo(() => {
    let res = [];
    if (!compareWith.experience) return [];
    if (compareWith.experience.onMobile) res.push("mobile");
    if (compareWith.experience.onDesktop) res.push("desktop");
    return res;
  }, [compareWith.experience]);

  const handleChange = (key: string) => (value: Dic<any>) => {
    send({
      type: "COMPARE_WITH",
      data: { compareWith: { ...compareWith, [key]: value } },
    });
  };

  const setDateRange = (value: any) => {
    send({ type: "SET_DATE_RANGE", data: { dateRange: value } });
  };

  const setDevices = (value: any) => {
    send({ type: "SET_DEVICES", data: { devices: value } });
  };

  const handleCompare = () => {
    const [translatedDate1, translatedDate2] =
      datesUtils.getTranslatedDateRange(
        dayjs(dateRange[0]),
        dayjs(dateRange[1])
      );
    send({
      type: "COMPARE",
      data: {
        compareWith: {
          experience,
          dateRange: [translatedDate1, translatedDate2],
          devices,
        },
      },
    });
  };

  const stopComparison = () => {
    send({
      type: "STOP_COMPARISON",
      data: { compareWith: { experience, dateRange, devices } },
    });
  };

  return (
    <Box id="container" sx={{ backdropFilter: "blur(10px)", zIndex: 999 }}>
      <Stack spacing={1}>
        <Box>
          <Grid container spacing={2}>
            <StyledGridItem item lg={3} sm={12} xs={12}>
              <ExperienceSelector
                loading={loading}
                experience={experience}
                handleChange={setExperience}
                initialExperience={experienceId}
                noSelector={!!noComparison}
              />
            </StyledGridItem>
            <StyledGridItem item xl={3} lg={5} sm={12} xs={12}>
              <DateRangePicker
                loading={loading}
                minDate={dateMin}
                dateRange={dateRange ?? []}
                onChange={setDateRange}
                maxDate={dayjs().subtract(2, "days")}
              />
            </StyledGridItem>
            <StyledGridItem item lg={4} sm={12} xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <MultipleDeviceSelector
                  loading={loading}
                  devices={devices}
                  setDevices={setDevices}
                  availableDevices={availableDevices}
                />
              </Stack>
            </StyledGridItem>
            {!noComparison && (
              <StyledGridItem item lg={2} sm={12} xs={12}>
                {!compare && (
                  <Stack sx={{ height: "100%" }} justifyContent="center">
                    <Button
                      btnVariant="outline"
                      loading={loading}
                      id="start-comparison"
                      onClick={handleCompare}
                      customStyle={{ width: "fit-content", height: "100%" }}
                    >
                      {t("start_comparison")}
                    </Button>
                  </Stack>
                )}
              </StyledGridItem>
            )}
          </Grid>
        </Box>
        <Collapse in={compare}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 2 }}
            >
              <Text>{t("and").toUpperCase()}</Text>
              <Box
                sx={{
                  width: "100%",
                  height: 2,
                  background: theme.customPalette.others.background,
                }}
              />
            </Stack>
            <Grid container spacing={2}>
              <StyledGridItem item lg={3} sm={12} xs={12}>
                <ExperienceSelector
                  experience={compareWith.experience}
                  handleChange={handleChange("experience")}
                  initialExperience={experienceId}
                  noSelector={!!noComparison}
                />
              </StyledGridItem>
              <StyledGridItem item lg={3} sm={12} xs={12}>
                <DateRangePicker
                  loading={loading}
                  minDate={null}
                  dateRange={compareWith.dateRange ?? []}
                  onChange={handleChange("dateRange")}
                  maxDate={dayjs().subtract(2, "days")}
                />
              </StyledGridItem>
              <StyledGridItem item lg={4} sm={12} xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <MultipleDeviceSelector
                    devices={compareWith.devices}
                    setDevices={handleChange("devices")}
                    availableDevices={availableDevicesForComparison}
                  />
                </Stack>
              </StyledGridItem>
              <StyledGridItem item lg={2} sm={12} xs={12}>
                <Stack
                  sx={{ width: "fit-content", height: "100%" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton onClick={stopComparison}>
                    <CloseIcon color={theme.customPalette.grey.default} />
                  </IconButton>
                </Stack>
              </StyledGridItem>
            </Grid>
          </Box>
        </Collapse>
      </Stack>
    </Box>
  );
};

export default Header;
